import Header from "../components/Header";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import Notification from "../components/Notification";
import Botmenu from "../components/Botmenu"
import MobileHeader from "../components/MobileHeader";
import { useTranslation } from "react-i18next";
import { QRCodeCanvas } from "qrcode.react";

export default function ContactUs() {

        const navigate = useNavigate();
        const [token, setToken] = useState(null); 
        const [isOpen, setIsOpen] = useState(false);
        const [notificationMessage, setNotificationMessage] = useState('');
        const [showNotification, setShowNotification] = useState(false);
        const { t, i18n } = useTranslation();
        const isRtl = i18n.language === 'fa' || i18n.language === 'ar';
        const [userData, setUserData] = useState({});
        const inviteLink = 'https://t.me/ownerluckyX';



    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [token]);
    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage(t('tokenExpired'));
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    return (
        <div className={isRtl ? "rtl" : 'ltr'}>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main className="contactUs mt-6">
                <h1 className="has-text-centered">{t('contactUs')}</h1>

                <div className=" has-text-centered mt-4">
                    <QRCodeCanvas className="qr" value={inviteLink} size={158}/>
                </div>
                <Link to={inviteLink}>
                <button className="mt-4" >
                    {t('openTelegramApp')}
                </button>
                </Link>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </div>
    )
}