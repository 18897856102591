import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Botmenu from "../components/Botmenu";
import Header from "../components/Header";
import MobileHeader from "../components/MobileHeader";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Notification from "../components/Notification";
import { useTranslation } from 'react-i18next'; // اضافه کردن import برای ترجمه

export default function Verify() {
    const { t } = useTranslation(); // استفاده از useTranslation
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [token, setToken] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState("");
    const [wallet, setWallet] = useState(null);

    useEffect(() => {
        const storedUserData = localStorage.getItem("userData");
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios
                .get("https://luckyx.cloud/api/v2/user/profile", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem("userData", JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage(t('tokenExpired')); // "Token expired, Login again"
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem("userData");
            navigate("/login", { replace: true });
        }, 3000);
    };

    const handleUpdateProfile = () => {
        // Validate email against the stored email
        const storedEmail = userData.email; // Get the email from localStorage
        if (verifyEmail !== storedEmail) {
            alert(t('emailMismatch')); // "ایمیل وارد شده با ایمیل ذخیره شده مطابقت ندارد."
            return;
        }

        // Check if wallet already exists
        if (userData.wallet) {
            alert(t('walletExists')); // "کیف پول از قبل وجود دارد. اگر اشتباهی ولت وارد شده است با پشتیبانی در ارتباط باشید."
            return;
        }

        // Validate wallet address (TRON wallet address)
        const tronWalletPattern = /^T[a-zA-Z0-9]/; // Example pattern for TRON wallet
        if (!tronWalletPattern.test(wallet)) {
            alert(t('invalidWallet')); // "لطفا آدرس کیف پول ترون را به درستی وارد کنید."
            return;
        }

        const formData = new FormData();
        formData.append('email', verifyEmail);
        formData.append('wallet', wallet);

        axios.post('https://luckyx.cloud/api/v2/user/editprofile', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                console.log(response.data);
                const updatedUserData = {
                    ...userData,
                    email: verifyEmail,
                    wallet: wallet
                };
                localStorage.setItem('userData', JSON.stringify(updatedUserData));
                setUserData(updatedUserData);

                // Show notification
                setNotificationMessage(t('walletVerified')); 
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                }, 3000); // Hide notification after 3 seconds
            })
            .catch(error => {
                console.error(error);
            });
    };

    return (
        <>
            <header>
                <div className="desktop_header">
                    <Header />
                    </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>
            <main>
                <section>
                    <div className="verify__page">
                        <div className="columns">
                            <div className="column">
                                <h3 className="has-text-centered">{t('verifyInstructions')}</h3> {/* "لطفا آدرس ترون و آدرس جیمیل را برای تایید برداشت پاداش ارسال کنید" */}
                            </div>
                            <div className="column input__div">
                                <input
                                    type="email"
                                    placeholder={t('enterEmail')} // "ایمیل خود را وارد کنید"
                                    value={verifyEmail}
                                    onChange={(e) => setVerifyEmail(e.target.value)}
                                />
                            </div>
                            <div className="column input__div">
                                <input
                                    type="text"
                                    placeholder={t('enterWalletAddress')} // "آدرس کیف پول خود را وارد کنید"
                                    value={wallet}
                                    onChange={(e) => setWallet(e.target.value)}
                                />
                            </div>
                            <div className="column">
                                <button onClick={handleUpdateProfile}>{t('confirm')}</button> {/* "تایید" */}
                            </div>
                        </div>
                        <Notification message={notificationMessage} open={showNotification} />
                        {userData.wallet && (
                            <div className="notification">
                                {t('walletRegistered')} {/* "ولت شما ثبت شده است. اگر اشتباهی ولت وارد شده است با پشتیبانی در ارتباط باشید." */}
                            </div>
                        )}
                    </div>
                </section>

                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </main>
        </>
    );
}