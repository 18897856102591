import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useTranslation } from 'react-i18next';

import Config from "../Config";

export default function MobileHeader() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [token, setToken] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'fa' || i18n.language === 'ar';

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage('Token expired, Login again');
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);


    const isPersian = userData.name && /^[\u0600-\u06FF\s]+$/.test(userData.name); 

    let displayName = '';
    if (userData.name) {
        if (isPersian) {
            displayName = userData.name.length > 3 ? `${userData.name.slice(-3)}` : userData.name;
        } else {
            displayName = userData.name.length > 3 ? `${userData.name.slice(0, 6)}` : userData.name;
        }
    }


    return (
        <div className="columns is-flex is-align-items-center" style={{ width: '100%' }}>
            <div className="column is-3-mobile logo has-text-centered px-3 py-0 pt-3">
                <img src="../assets/images/logo2.png" alt="" className={isRtl ? 'logortl' : 'logoltr'} />
            </div>
            {isLoggedIn ? (
                <>
                    <div className="column is-7-mobile mt-3 is-flex mobile__header_wallet">
                        <Link to={'/wallet'}>
                            <span className="mobile__header_wallet_info1">
                                <AccountBalanceWalletIcon className="iconm" />
                            </span>
                        </Link>
                        <div className="mobile__header_wallet_info_inner">
                            <p>{userData.balance}</p>
                            <img src="../assets/icon/trx.png" alt="" />
                        </div>
                    </div>
                    <div className="column has-text-left ml-3 p-0 mobile__header_wallet_info2" >
                        <div className="name-container">
                        <p>{displayName}</p>
                        </div>
                        <img src={`${Config.baseurl}/${userData.avatar}`} />
                    </div>
                </>
            ) : (
                <div className="column" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <div className="buttons pl-4" style={{ width: '230px' }}>
                        <div className="buttonn ml-2">
                            <Link to={'/sign-up'} className="btnn fx01">
                                <span>{t('register')}</span>
                            </Link>
                        </div>
                        <div className="buttonn">
                            <Link to={'/login'} className="btnn fx02">
                                <span>{t('login')}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}