import Header from "../components/Header";
import PersonIcon from '@mui/icons-material/Person';
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import moment from 'moment-jalaali';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Notification from "../components/Notification";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Botmenu from "../components/Botmenu";
import MobileHeader from "../components/MobileHeader";
import { useTranslation } from 'react-i18next';

export default function Wallet() {
    const [showInfo, setShowInfo] = useState(null);
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const [deposits, setDeposits] = useState([]);
    const [withdraw, setWithdraw] = useState([]);
    const [trxPrice, setTrxPrice] = useState(null);

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                })
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage(t('tokenExpired'));
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = userData.token;
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        };
        axios.get('https://luckyx.cloud/api/v2/user/deposits', config)
            .then(response => {
                const data = response.data.deposits;
                setDeposits(data);
            });

        axios.get('https://luckyx.cloud/api/v2/user/withdraws', config)
            .then(response => {
                const data = response.data.withdraws;
                setWithdraw(data);
            });

        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        const fetchTrxPrice = async () => {
            try {
                const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=tron&vs_currencies=usd');
                const priceInUsd = response.data.tron.usd;
                setTrxPrice(priceInUsd);
            } catch (error) {
                // Handle error
            }
        };

        fetchTrxPrice();

        const intervalId = setInterval(() => {
            fetchTrxPrice();
        }, 30000);

        return () => clearInterval(intervalId);
    }, []);

    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'fa' || i18n.language === 'ar';

    return (
        <div className={isRtl ? 'rtl' : 'ltr'}>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main className="wallet_main">
                <section>
                    <div className="wallet_page container mt-4">
                        <div className="columns is-flex-mobile top_wal ">
                            <div className="column is-8 has-text-right is-flex is-align-items-center">
                                <Link to={'/Profile'}>
                                    <PersonIcon className="top_wal_icon" />
                                </Link>
                                <p className="mr-3">{t('welcomeBack')} {userData.name}</p>
                            </div>
                            <div className="column">
                                <div className={isRtl ? 'innerrtl  has-text-centered' : 'innerltr  has-text-centered'}>
                                    <div className="inner">
                                        <div className="last_price">
                                            <div className="is-flex">
                                                <p>{t('currentPrice')}</p>
                                                <span className="dot"></span>
                                            </div>
                                            <div>
                                                <h3>1<strong>TRX</strong>: {trxPrice ? `${trxPrice}$` : t('loading')}</h3> {/* Translate "Loading" */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="columns wallet__have">
                            <div className="column has-text-centered">
                                <h2>{userData && userData.balance ? userData.balance.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }) : '0.0000'}<strong className="ml-1">TRX</strong></h2>
                            </div>
                        </div>

                        <div className="columns is-flex-mobile wallet__btn mt-4">
                            <div className="column has-text-centered">
                                <Link to={"/wallet/withdraw"}>
                                    <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {t('withdraw')}
                                        <ArrowCircleUpIcon className="mx-2" />
                                    </button>
                                </Link>
                            </div>

                            <div className="column has-text-centered">
                                <Link to={'/wallet/deposit'}>
                                    <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {t('deposit')}
                                        <ArrowCircleDownIcon className="mx-2" />
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="columns container">
                        <div className="column in__info">
                            <button
                                onClick={() => setShowInfo('deposits')}
                                className="button is-primary"
                            >
                                {t('deposits')}
                            </button>

                            <button
                                onClick={() => setShowInfo('withdraw')}
                                className="button is-primary"
                            >
                                {t('withdraw')}
                            </button>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="columns wallet_trs container">
                        {showInfo === 'deposits' && (
                            <div className="card" style={{ width: '100%', marginBottom: '6em', marginTop: '.8em' }}>
                                <div className="card-header">
                                    <p className="card-header-title">{t('depositTransactions')}</p>
                                </div>
                                <div className="card-content">
                                    <table className="table" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th className="has-text-centered">{t('date')}</th>
                                                <th className="has-text-centered">{t('status')}</th>
                                                <th className="has-text-centered">{t('amount')}</th>
                                            </tr>
                                        </thead>
                                        {deposits.map((deposit, index) => (
                                            <tr key={index}>
                                                <td className="has-text-centered">{moment.unix(deposit.date).format('jYYYY/jMM/jDD')}</td>
                                                <td className="has-text-centered">
                                                    <span className={`tag ${deposit.status === 'pending' ? 'is-warning' : 'is-success'}`}>
                                                        {deposit.status}
                                                    </span>
                                                </td>
                                                <td className="has-text-centered">{deposit.amount} TRX</td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        )}
                        {showInfo === 'withdraw' && (
                            <div className="card" style={{ width: '100%', marginBottom: '6em', marginTop: '.8em' }}>
                                <div className="card-header">
                                    <p className="card-header-title">{t('withdrawTransactions')}</p>
                                </div>
                                <div className="card-content">
                                    <table className="table" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th className="has-text-centered">{t('date')}</th>
                                                <th className="has-text-centered">{t('status')}</th>
                                                <th className="has-text-centered">{t('amount')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {withdraw.map((withdrawal, index) => (
                                                <tr key={index}>
                                                    <td className="has-text-centered">{moment.unix(withdrawal.date).format('jYYYY/jMM/jDD')}</td>
                                                    <td className="has-text-centered">
                                                        <span className="tag is-success">{t(' موفق')}</span>
                                                    </td>
                                                    <td className="has-text-centered">{withdrawal.amount} TRX</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </div>
    )
}