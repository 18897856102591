// src/components/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      fa: {
        translation: {
          openTelegramApp : "باز کردن برنامه تلگرام",
          contactUs : "ارتباط با ما",
          "platform_intro": "با پلتفرم ما آشنا شوید تا بیشتر بدانید و فرصت را از دست ندهید..!",
          "greeting": "سلام دوستان! با پلتفرم ما آشنا شوید! با پلتفرم ما، شما به تمام اطلاعاتی که نیاز دارید تا تصمیمات آگاهانه بگیرید، دسترسی خواهید داشت. پلتفرم ما یک محل جامع برای تمام اطلاعاتی است که شما نیاز دارید. پس منتظر چه هستید؟ بیایید شروع کنیم!",
          "about_us": "درباره ما",
          "about_us_content": "لاکی ایکس یک شرکت فروش مستقیم است که در زمینه بازاریابی شبکه‌ای و بازارهای مالی و شرکت‌های فناوری فعال است و در اواخر سال ۲۰۲۴ تأسیس شده است. در لاکی ایکس، هدف ما سرمایه‌گذاری در شرکت‌هایی است که بشریت را بهتر می‌کنند و مشکلات بزرگ را با کمک فناوری حل می‌کنند. ما در پروژه‌ها به‌طور بلندمدت سرمایه‌گذاری می‌کنیم و درک می‌کنیم که موفقیت نیاز به تلاش، فداکاری و پشتکار دارد. یکی از آخرین پروژه‌های لاکی ایکس در سال ۲۰۲۵، کار در بازارهای مالی است که بیشترین سود را برای کاربران این شبکه با تلاش‌های خدمات مالی ارز دیجیتال فراهم کرده است.",
          "team": "تیم لاکی ایکس",
          "founder": "آرنولدا ناوسدا - بنیان‌گذار",
          "founder_info": "آقای آرنولدا ناوسدا دارای مدرک کارشناسی اقتصاد، MBA مالی، EMBA مدیریت عمومی بین‌المللی، نقش‌های اجرایی ارشد در مالی، سرمایه‌گذاری و توسعه کسب‌وکار در دامنه وسیعی از شرکت‌های بین‌المللی که صنایع مختلفی از جمله لجستیک، ساخت‌وساز، خرده‌فروشی، ماشین‌آلات سنگین، ورزش، فرآوری فلز، خدمات مالی و مدیریت سرمایه‌گذاری را پوشش می‌دهند. سرمایه‌گذار با تجربه عملی در مدیریت سرمایه‌گذاری، با سرمایه‌گذاری در خدمات مالی، املاک و شرکت‌های فناوری دارند.",
          "product_roadmap": "نقشه راه محصول",
          "roadmap_content": "نقشه راه محصول مسیر پیش رو را نشان می‌دهد، به تیم‌ها کمک می‌کند برنامه‌ریزی کنند و راهنمایی برای ارائه محصول است.",
          "steps": "قدم‌ها",
          "step1": "قدم اول تحقیق پروژه",
          "step1_content": "تحقیق پروژه ستون فقرات هر پروژه موفقی است. این شامل جمع‌آوری اطلاعات، تعیین اهداف و تحلیل داده‌ها برای اطمینان از قابل دستیابی بودن پروژه است. بدون تحقیق مناسب، پروژه‌ها می‌توانند به دلیل کمبود دانش شکست بخورند.",
          "step2": "قدم دوم ایده‌پردازی",
          "step2_content": "فقط خواستم یک ایده‌پردازی برای پروژه آینده‌مون مطرح کنم. بیایید با تصویر بزرگ شروع کنیم و بعد به جزئیات بپردازیم. اینطوری می‌توانیم مطمئن بشیم که دیدگاه‌مون در مسیر درست قرار داره و در عین حال کارها رو هم سروسامان می‌دهیم.",
          "step3": "قدم سوم طراحی پیش‌نویس اولیه",
          "step3_content": "فقط خواستم پیش‌نویس اولیه طراحی‌ام رو به اشتراک بذارم! به سبک مینیمالیستی با یک رنگ شاداب رفتم. نظرتون چیه؟ آماده‌ام برای هر گونه پیشنهاد یا بازخورد. نمی‌توانم صبر کنم ببینم این چطور در میاد و چه موفقیتی به همراه داره!",
          "step4": "قدم چهارم طراحی نهایی",
          "step4_content": "پس از کلی تکرار و تغییرات بی‌پایان، بالاخره به طراحی نهایی رسیدیم. این طراحی شیک و مدرن است و به خوبی جوهره آنچه را که به دنبالش بودیم، به تصویر می‌کشد. نمی‌توانیم بیشتر از این برای راه‌اندازی آن هیجان‌زده باشیم!",
          "step5": "قدم پنجم توسعه پروژه",
          "step5_content": "پس ما در حال حاضر در عمق توسعه پروژه هستیم و همه چیز به خوبی پیش می‌رود. ما یک برنامه محکم داریم و تیم‌مون با تمام قوا در حال کار است. هنوز کارهایی برای انجام دادن وجود دارد، اما از آنچه در حال ساختش هستیم هیجان‌زده‌ایم.",
          "final_step": "قدم نهایی راه‌اندازی پروژه",
          "final_step_content": "خوب بچه‌ها، وقتشه که این پروژه رو به راه بندازیم! ما هفته‌ها درباره‌اش صحبت کردیم و حالا وقتشه که راه‌اندازی کنیم. همه قطعات در جای خودشون هستن، پس بیایید کار کنیم و این کار رو به واقعیت تبدیل کنیم!",
          "pricing_plan": "برنامه قیمت‌گذاری ما",
          "pricing_content": "ما بسته‌هایی را انتخاب کردیم که تمام افراد جامعه بتوانند مدیریت مالی و قدرت ریسک‌پذیری خود را آغاز کنند.",
          "products_services": "محصولات و خدمات لاکی ایکس",
          "product1": "محصول اول: ارز دیجیتال LX",
          "product1_content": "یکی از محصولات لاکی ایکس، عرضه اولیه توکن LX است که به کاربران در بسته‌های ۵۰هزار تایی ارائه می‌شود. هر بسته به کاربران به قیمت ۱۰۰TRX فروخته می‌شود و کاربران هیچ محدودیتی در سرمایه‌گذاری برای خرید توکن‌های LX ندارند.",
          "more_info": "برای اطلاعات بیشتر، روی لینک زیر کلیک کنید و نقشه راه ارز دیجیتال LX را بخوانید.",
          "product2": "محصول دوم: بلیت قرعه‌کشی لاکی ایکس",
          "product2_content": "سایر محصولات لاکی ایکس، بلیت‌های قرعه‌کشی هستند که بین ۲۰ خریدار قرعه‌کشی می‌شود و در هر دوره دو جایزه نقدی به کاربران اهدا می‌شود؛ یکی از جوایز به برنده و دیگری به معرف برنده داده می‌شود. مبلغ بلیت‌ها و جوایز در هر دوره متفاوت است.",
          "income_plan": "برنامه تولید درآمد لاکی ایکس",
          "income_plan1": "طرح ۱: کمیسیون ارجاع مستقیم ۱۰٪",
          "income_plan1_content": "دوست عزیز، در حساب کاربری‌تان دو لینک ارجاع وجود دارد، یکی لینک ارجاع سمت راست و دیگری لینک ارجاع سمت چپ، که می‌توانید به طور نامحدود محصولات شرکت را به دوستان‌تان معرفی کنید و کمیسیون دریافت کنید.",
          "income_plan2": "طرح ۲: کمیسیون ساخت تیم ۱۰٪",
          "income_plan2_content": "دوست عزیز، تیم فروش شما به دو تیم فروش تقسیم می‌شود، سمت چپ و سمت راست، و برای بالانس کردن حجم فروش، شما ۱۰٪ کمیسیون از حجم فروش تیم کوچکتر دریافت می‌کنید و درآمد روزانه‌تان از این طرح 2000 TRX است.",
          "welcome_content": "به سایت تجارت ما خوش آمدید! ما بهترین برنامه‌های سودآور را در بازار تجارت ارائه می‌دهیم. شروع به استفاده از لینک ارجاع کنید!",
          welcomesite: "به سایت لاکی ایکس خوش آمدید",
          title: "لاکی ایکس",
          hopeBeWinner: "آرزو می‌کنیم که برنده خوش شانس این دوره شما باشید",
          unknown: "ناشناخته",
          yourFriendstitle: "دوستان شما",
          performanceSummary: "خلاصه عملکرد:",
          rightSalesVolume: "حجم فروش راست",
          totalSalesVolume: "حجم فروش کل",
          leftSalesVolume: "حجم فروش چپ",
          "mySalesTeam": "تیم فروش من",
          "date": "تاریخ",
          "balance": "تعادل",
          "rightPoints": "امتیاز راست",
          "leftPoints": "امتیاز چپ",
          "saveRightPoints": "امتیاز ذخیره راست",
          "saveLeftPoints": "امتیاز ذخیره چپ",
          "deposited": "واریز شده",
          "inProgress": "در حال انجام",
          "noParent": "بدون والد",
          "you": "شما",
          "noRef": "بدون معرف",
          "noName": "بدون نام",
          "participateInLottery": "شرکت در لاتاری",
          "buyTicketDescription": "دوست عزیز هر دوره قرعه کشی بین 20 نفر انجام می‌شود و قیمت هر بلیط 100 ترون است و جایزه برنده 500 ترون و جایزه معرف برنده هم 100 ترون است و در هر دوره شما فقط دو بلیط می‌توانید خریداری کنید و بعد از قرعه کشی جوایز به کیف پولتان واریز می‌شود.",
          "required": "ضروری",
          "maxTwoTickets": "حداکثر 2 بلیط می‌توانید خریداری کنید",
          "invalidEmail": "ایمیل نامعتبر است",
          "purchaseSuccessful": "با موفقیت خریداری شد",
          "tokenExpiredLoginAgain": "توکن به اتمام رسید, دوباره وارد شوید",
          "errorOccurred": "یک خطا رخ داده است. لطفاً بعداً دوباره تلاش کنید.",
          "ticketCountF": "تعداد بلیط",
          "thankYouForPurchase": "ممنون از خریداری شما",
          "tokensWillBeTransferred": "تا 24 ساعت آینده ارز های LX به کیف پول شما انتقال داده خواهد شد با تشکر از صبر و شکیبایی شما",
          "buyTokenLX": "خرید توکن LX",
          "tokenDescription": "لاکی ایکس برای عرضه اولیه توکن هارا بصورت 50000 واحدی پک بندی کرده است و مبلغ هر پک از 100 ترون دراختیار کاربران قرار داده میشود و شما هیچ محدودیتی در اضافه خریدن این ارز ندارید. به ازای فروش هر 5 میلیون واحد 10 ترون به قیمت هر پک اضافه خواهد شد.",
          "enterTokenCount": "تعداد پک را وارد کنید",
          "invalidWalletAddress": "آدرس ولت نامعتبر است.",
          "editProfileName": "لطفا نام کاربری خود را از قسمت پروفایل ویرایش کنید.",
          "insufficientBalance": "موجودی کافی نیست",
          "cannotBuyMoreThanTwo": "نمیتوانید بیشتر از 2 توکن خریداری کنید",
          "tokenExpiredLoginAgain": "توکن به اتمام رسید, دوباره وارد شوید",
          "errorOccurred": "یک خطا رخ داده است. لطفاً بعداً دوباره تلاش کنید.",
          "walletAddressTon": "ولت تون هاب",
          "enterWalletAddress": "ولت تون هاب خود را وارد کنید.",
          "tokenCount": "تعداد توکن",
          "eachPack50k": "هر پک 50k",
          "pricePerPack": "قیمت هر پک",
          "referralLink": "لینک دعوت",
          "inviteTelegram": "دعوت از تلگرام",
          "inviteTelegramDescription": "با دعوت از طریق تلگرام سود بیشتری دریافت خواهید کرد",
          "shareRightTelegram": "اشتراک گذاری کد راست در تلگرام",
          "shareLeftTelegram": "اشتراک گذاری کد چپ در تلگرام",
          "inviteSiteCodeRight": "دعوت از سایت کد راست",
          "inviteSiteCodeLeft": "دعوت از سایت کد چپ",
          "copyLink": "کپی لینک",
          "directInviteReport": "گزارش دعوت مستقیم",
          "organizationalChartReport": "گزارش چارت سازمانی",
          "totalProfit": "کل سود",
          "showDirectTeam": "نمایش تیم سطح مستقیم",
          "showOrganizationalChart": "نمایش چارت سازمانی",
          "linkCopied": "لینک شما کپی شد!",
          "image": "تصویر",
          "assets": "دارایی",
          "notProvided": "وارد نشده",
          "reward": "پاداش",
          "noData": "اطلاعاتی برای نمایش وجود ندارد",
          "totalRewards": "کل پاداش",
          "dailyPerformance": "عملکرد روزانه",
          "count": "تعداد",
          "wallet": "ولت",
          "noTickets": "تیکتی خریداری نشده است",
          "previousLotteryInfo": "اطلاعات مسابقه قبلی",
          "startDate": "تاریخ شروع",
          "endDate": "تاریخ اتمام",
          "winner": "برنده",
          "ticketCount": "تعداد بلیط های خریداری شده",
          "viewAllWinners": "مشاهده تمام برندگان",
          "allWinners": "تمام برندگان",
          "name": "نام",
          "date": "تاریخ",
          "referrer": "معرف",
          "noReferrer": "ندارد",
          "fillAtLeastOneField": "لطفا حداقل یک فیلد را پر کنید.",
          "username": "نام کاربری",
          "newUsername": "نام کاربری جدید",
          "newEmail": "جیمیل جدید",
          "avatar": "آواتار",
          "updateProfile": "بروزرسانی پروفایل",
          "changePassword": "ویرایش رمزعبور",
          "changePasswordLabel": "تغییر رمز",
          "oldPassword": "رمز قدیمی",
          "newPassword": "رمز جدید",
          "updatePassword": "بروزرسانی رمز عبور",
          "profileUpdated": "پروفایل با موفقیت بروزرسانی شد",
          "profileUpdateSuccess": "اطلاعات پروفایل شما با موفقیت بروزرسانی شد",
          "emailMismatch": "ایمیل وارد شده با ایمیل ذخیره شده مطابقت ندارد.",
          "walletExists": "کیف پول از قبل وجود دارد. اگر اشتباهی ولت وارد شده است با پشتیبانی در ارتباط باشید.",
          "invalidWallet": "لطفا آدرس کیف پول ترون را به درستی وارد کنید.",
          "walletVerified": "کیف پول شما با موفقیت تایید شد.",
          "verifyInstructions": "لطفا آدرس ترون و آدرس جیمیل را برای تایید برداشت پاداش ارسال کنید.",
          "enterEmail": "ایمیل خود را وارد کنید",
          "enterWalletAddress": "آدرس کیف پول خود را وارد کنید",
          "confirm": "تایید",
          "walletRegistered": "ولت شما ثبت شده است. اگر اشتباهی ولت وارد شده است با پشتیبانی در ارتباط باشید.",
          "invalidEmailOrPassword": "ایمیل یا رمز عبور نامعتبر است.",
          "blockchainServerError": "مشکل در سرور بلاکچین بوجود آمده است",
          "walletAddress": "آدرس کیف پول",
          "minimum30trx": "حداقل 30trx",
          "fee": "کارمزد",
          "loadingWithdraw": "بارگذاری برداشت...",
          "minimumDeposit": "حداقل واریزی 103 ترون است.",
          "pleaseFillField": "لطفا فیلد را پر کنید",
          "depositError": "خطا در واریز. لطفاً دوباره تلاش کنید.",
          "minimumDepositAmount": "حداقل واریزی: {{amount}} trx",
          "deposit": "واریز",
          "displayOrganizationalChart": "نمایش درختواره",
          "directInvitationReward": "پاداش دعوت مستقیم",
          "organizationalChartReward": "پاداش تیم فروش",
          "level1Members": "نفرات سطح 1",
          "organizationalChartMembers": "نفرات چارت سازمانی",
          "receivedReward": "پاداش دریافتی",
          password: "رمز عبور",
          confirmPassword: "تکرار رمز عبور",
          referralCode: "کد دعوت",
          enterYourEmail: "ایمیل خود را وارد کنید",
          enterYourPassword: "رمز عبور خود را وارد کنید",
          repeatYourPassword: "تکرار رمز عبور خود را وارد کنید",
          enterReferralCode: "کد دعوت را وارد کنید",
          acceptTerms: "من قوانین را میپذیرم که 18 سال سن دارم",
          requestPasswordReset: "برای دریافت پسوورد پیام دهید",
          contactAdmin: "ارتباط با ادمین",
          signUp: "ثبت نام",
          alreadyHaveAccount: "حساب کاربری دارید؟",
          login: "ورود",
          invalidReferralCode: "کد دعوت نامعتبر است",
          userAlreadyExists: "این یوزر از قبل موجود است",
          completeAllFields: "لطفا تمام فیلد هارا کامل کنید",
          google: "گوگل",
          telegram: "تلگرام",
          emailAddress: "آدرس ایمیل",
          password: "رمز عبور",
          enterYourEmail: "ایمیل خود را وارد کنید",
          enterYourPassword: "رمز عبور خود را وارد کنید",
          login: "ورود",
          signUp: "ثبت نام",
          orLoginWith: "یا وارد شوید از طریق",
          educationalMenu: "منوی آموزش",
          close: "بستن",
          educationalVideos: "فیلم آموزشی",
          rewards: "جوایز",
          support: "پشتیبانی",
          requestPasswordReset: "برای دریافت پسوورد پیام دهید",
          contactAdmin: "ارتباط با ادمین",
          forgotPassword: "رمز عبور خود را فراموش کردید؟",
          dontHaveAccount: "حساب کاربری ندارید؟",
          welcome: "خوش آمدید",
          welcomeBack: "خوش اومدی",
          buyToken: "خرید توکن",
          register: "ثبت نام",
          buyTicket: "خریدن بلیط",
          login: "ورود",
          home: "خانه",
          specialCommission: "10% پورسانت ویژه واریز مستقیم",
          referFriends: "به دوستانتان 10% پورسانت بگیرید LX با معرفی ارز ",
          or: "یا",
          sharertl: "اشتراک‌گذاری راست ",
          shareltr: "اشتراک‌گذاری چپ ",
          share: "اشتراک گذاری",
          winners: "برندگان",
          viewAll: "مشاهده همه",
          myStats: "آمار من",
          level1Members: "نفرات سطح 1",
          level2Members: "نفرات سطح 2",
          receivedRewards: "پاداش دریافتی",
          yourWallet: "کیف پول شما",
          myTickets: "بلیط های من",
          myTokens: "توکن های من",
          prizeWinners: "برنده های جوایز",
          verifyWithdrawalAddress: "تایید آدرس برداشت پاداش",
          inviteFriends: "دعوت از دوستان",
          yourRewards: "پاداش شما",
          yourFriends: "دوستان شما",
          editProfile: "ویرایش پروفایل",
          linkCopied: "لینک شما کپی شد!",
          menu: "منو",
          profile: "پروفایل",
          yourWallet: "کیف پول",
          lottery: "لاتاری",
          welcomeMessage: "به لاکی ایکس خوش آمدید",
          buyTicketMessage: "برای شرکت در لاتاری بلیط خریداری کنید",
          registerOrLoginMessage: "برای استفاده از خدمات ثبت نام کنید و یا وارد شوید",
          logout: "خروج از حساب",
          myReferralLink: "لینک دعوت من",
          myRewards: "پاداش من",
          myTeam: "مجموعه من",
          myTickets: "بلیط های من",
          deposit: "واریز",
          withdraw: "برداشت",
          transactions: "تراکنش ها",
          participants: "شرکت کنندگان",
          incomePlan: "آموزش",
          aboutUs: "درباره ما",
          currentPrice: "قیمت لحظه‌ای",
          loading: "در حال دریافت...",
          depositTransactions: "تراکنش‌های واریز",
          withdrawTransactions: "تراکنش‌های برداشت",
          status: "وضعیت",
          amount: "مبلغ",
          successful: "موفق",
          deposits: "واریزها",
          lotteryPrize: "جایزه لاتاری",
          lotteryPrizeDescription: "جایزه این دوره از مسابقات لاتاری",
          viewWinners: "مشاهده برنده",
          join: "شرکت",
          userName: "نام کاربر",
          prize: "جایزه",
          purchaseDate: "تاریخ خرید",
          userName: "نام کاربر",
          email: "ایمیل",
          purchaseDate: "تاریخ خرید",
          noTicketsMessage: "تیکتی خریداری نشده است",
          tokenExpired: "توکن منقضی شده است، لطفاً دوباره وارد شوید",
        }
      },
      en: {
        translation: {
          openTelegramApp : "Open Telegram App",
          contactUs : "Contact Us",
          "platform_intro": "Get to know our platform to learn more and don't miss out..!",
          "greeting": "Hey folks! Meet our platform! With our platform, you'll get access to all the info you need to make informed decisions. Our platform is your one-stop-shop for everything you need to know. So what are you waiting for? Let's get started!",
          "about_us": "About Us",
          "about_us_content": "Lucky X is a direct selling company that is active in the field of network marketing and financial markets and technology companies and was established in late 2024. At LuckyX, our goal is to invest in companies that make humanity better and solve big problems made possible by technology. We invest in projects for a long time and understand that success requires sheer effort, dedication and perseverance. One of the last projects of Lucky X in 2025 is to work in the financial markets, which has provided the most benefit to the users of this network with the efforts of digital currency financial services.",
          "team": "Lucky X Team",
          "founder": "Arnoldas Nauseda",
          "founder_info": "B.Ec., MBA Finance, EMBA International General Management, Senior executive roles in finance, investment and business development in a wide range of international companies covering industries such as logistics, construction, retail, heavy machinery, sports, metal processing, financial services and investment management. Investor with hands-on experience in investment management, having investments in financial services, real estate and tech companies.",
          "product_roadmap": "Product Roadmap",
          "roadmap_content": "A product roadmap shows the path ahead, helps teams plan, and guides the delivery of the product.",
          "steps": "Steps",
          "step1": "Project Research",
          "step1_content": "Project research is the backbone of any successful project. It involves gathering information, setting objectives, and analyzing data to ensure the project is achievable. Without proper research, projects can fail due to lack of knowledge.",
          "step2": "Framing Idea",
          "step2_content": "Just wanted to throw out a framing idea for our upcoming project. Let's start with the big picture and then work our way down to the details. This way we ensure our vision stays on track while also ironing out.",
          "step3": "Design First Draft",
          "step3_content": "Just wanted to share my design first draft! I went for a minimalist look with a pop of color. What do you think? Open to any suggestions or feedback. Can't wait to see how this turns out over all kind of success!",
          "step4": "Final Design",
          "step4_content": "So after lots of reiterations, and endless tweaking, we finally landed on the final design. It's slick, it's modern, and it perfectly captures the essence of what we were aiming for. We couldn't be more excited to launch it!",
          "step5": "Project Development",
          "step5_content": "So we're deep in project development here, and things are coming along nicely. We've got a solid plan in place and our team is firing on all cylinders. There's still work to be done, but we're excited about what we're building.",
          "final_step": "Launch Project",
          "final_step_content": "Alright folks, it's time to get this project off the ground! We've been talking about it for weeks, and now it's time to launch. We've got all the pieces in place, so let's get to work and make this thing happen!",
          "pricing_plan": "Our Pricing Plan",
          "pricing_content": "We chose packages that all people in the society can start their own financial management and risk-taking power.",
          "products_services": "Lucky X Product",
          "product1": "Product 1: LX Digital Currency",
          "product1_content": "One of Lucky X's products is the initial release of the LX token, which is offered to users in packs of 50K. Each pack is offered to users at a price of 100TRX, and users have no restrictions on investing in the purchase of LX tokens.",
          "more_info": "For more information, click on the link below and read the LX digital currency roadmap.",
          "product2": "Product 2: Lucky X Lottery Ticket",
          "product2_content": "Other products of Lucky X are lottery tickets which are drawn among 20 buyers, and two cash prizes are given to the users in each period; one of the prizes is given to the winner and the other to the winner's representative. The amount of tickets and rewards varies in each period.",
          "income_plan": "Lucky X Income Generating Plan",
          "income_plan1": "Plan 1: Direct Referral Commission 10%",
          "income_plan1_content": "Dear friend, there are two referral links in your user account, the right referral link and the left referral link, which you can endlessly introduce the company's products to your friends and receive commission.",
          "income_plan2": "Plan 2: Team Building Commission 10%",
          "income_plan2_content": "Dear friend, your sales team is divided into two sales teams, left and right, and for balancing the sales volume, you receive 10% commission from the sales volume of the small team, and your daily income from this plan is 2000 TRX.",
          "welcome_content": "Welcome to our trading site! We offer the best profit plans in the trading market. Start using the referral link!",
          welcomesite: "Welcome to Lucky X website",
          title: "LuckyX",
          hopeBeWinner: "We hope you are the lucky winner of this round",
          unknown: "unknown",
          yourFriendstitle: "your friends",
          "performanceSummary": "Performance Summary:",
          "rightSalesVolume": "Right Sales Volume",
          "totalSalesVolume": "Total Sales Volume",
          "leftSalesVolume": "Left Sales Volume",
          "mySalesTeam": "My Sales Team",
          "date": "Date",
          "balance": "Balance",
          "rightPoints": "Right Points",
          "leftPoints": "Left Points",
          "saveRightPoints": "Saved Right Points",
          "saveLeftPoints": "Saved Left Points",
          "deposited": "Deposited",
          "inProgress": "In Progress",
          "noParent": "No Parent",
          "you": "You",
          "noRef": "No Referrer",
          "noName": "No Name",
          "participateInLottery": "Participate in Lottery",
          "buyTicketDescription": "Dear friend, each lottery draw is held among 20 participants, and the price of each ticket is 100 TRX. The winner's prize is 500 TRX, and the referrer's prize is also 100 TRX. In each round, you can only purchase two tickets, and after the draw, the prizes will be credited to your wallet. Buy a ticket to participate in the lottery; ",
          "required": "Required",
          "maxTwoTickets": "You can buy a maximum of 2 tickets.",
          "invalidEmail": "Invalid email.",
          "emailMismatch": "The entered email does not match the stored email.",
          "purchaseSuccessful": "Successfully purchased.",
          "ticketCountF": "Ticket Count",
          "thankYouForPurchase": "Thank you for your purchase.",
          "tokensWillBeTransferred": "LX tokens will be transferred to your wallet within 24 hours. Thank you for your patience.",
          "buyTokenLX": "Buy LX Token",
          "tokenDescription": "LuckyX has packaged the initial token offering in batches of 50,000 units, with each batch priced at 100 TRX. There is no limit to how many of these tokens you can purchase. For every 5 million units sold, an additional 10 TRX will be added to the price of each batch.",
          "enterTokenCount": "Please enter the number of packs.",
          "invalidWalletAddress": "Invalid wallet address.",
          "editProfileName": "Please edit your username in the profile section.",
          "insufficientBalance": "Insufficient balance.",
          "cannotBuyMoreThanTwo": "You cannot purchase more than 2 tokens.",
          "tokenExpiredLoginAgain": "Token has expired, please login again.",
          "errorOccurred": "An error occurred. Please try again later.",
          "walletAddressTon": "Ton Wallet Address",
          "enterWalletAddress": "Enter your wallet address.",
          "tokenCount": "Token Count",
          "eachPack50k": "Each pack is 50k",
          "pricePerPack": "Price per pack",
          "referralLink": "Referral Link",
          "inviteTelegram": "Invite via Telegram",
          "inviteTelegramDescription": "You will earn more by inviting through Telegram",
          "shareRightTelegram": "Share right code on Telegram",
          "shareLeftTelegram": "Share left code on Telegram",
          "inviteSiteCodeRight": "Invite site right code",
          "inviteSiteCodeLeft": "Invite site left code",
          "copyLink": "Copy Link",
          "directInviteReport": "Direct Invite Report",
          "organizationalChartReport": "Organizational Chart Report",
          "totalProfit": "Total Profit",
          "showDirectTeam": "Show Direct Team",
          "showOrganizationalChart": "Show Organizational Chart",
          "linkCopied": "Your link has been copied!",
          "image": "Image",
          "assets": "Assets",
          "notProvided": "Not provided",
          "noData": "No data available.",
          "totalRewards": "Total Rewards",
          "dailyPerformance": "Daily Performance",
          "reward": "Reward",
          "noData": "No data available to display.",
          "count": "Count",
          "wallet": "Wallet",
          "noTickets": "No tickets purchased.",
          "previousLotteryInfo": "Previous Lottery Information",
          "startDate": "Start Date",
          "endDate": "End Date",
          "winner": "Winner",
          "ticketCount": "Number of Tickets Purchased",
          "viewAllWinners": "View All Winners",
          "allWinners": "All Winners",
          "name": "Name",
          "date": "Date",
          "referrer": "Referrer",
          "noReferrer": "None",
          "fillAtLeastOneField": "Please fill in at least one field to update your profile.",
          "username": "Username",
          "newUsername": "New Username",
          "newEmail": "New Email",
          "avatar": "Avatar",
          "updateProfile": "Update Profile",
          "changePassword": "Change Password",
          "changePasswordLabel": "Change Password",
          "oldPassword": "Old Password",
          "newPassword": "New Password",
          "updatePassword": "Update Password",
          "profileUpdated": "Profile updated successfully",
          "profileUpdateSuccess": "Your profile information has been updated successfully.",
          "emailMismatch": "The entered email does not match the stored email.",
          "walletExists": "Wallet already exists. If a wrong wallet has been entered, please contact support.",
          "invalidWallet": "Please enter a valid TRON wallet address.",
          "walletVerified": "Your wallet has been successfully verified.",
          "verifyInstructions": "Please provide your TRON address and email address to verify your reward withdrawal.",
          "enterEmail": "Enter your email",
          "enterWalletAddress": "Enter your wallet address",
          "confirm": "Confirm",
          "walletRegistered": "Your wallet is registered. If a wrong wallet has been entered, please contact support.",
          "invalidEmailOrPassword": "Invalid email or password.",
          "blockchainServerError": "There is a problem with the blockchain server.",
          "walletAddress": "Wallet Address",
          "minimum30trx": "Minimum 30 TRX",
          "fee": "Fee",
          "loadingWithdraw": "Loading withdrawal...",
          "minimumDeposit": "Minimum deposit is 103 TRX.",
          "pleaseFillField": "Please fill the field.",
          "depositError": "Error in deposit. Please try again.",
          "tokenExpired": "Token expired, please login again.",
          "minimumDepositAmount": "Minimum deposit: {{amount}} TRX",
          "loading": "Loading...",
          "deposit": "Deposit",
          "displayOrganizationalChart": "Display Tree Structure",
          "directInvitationReward": "Direct Invitation Reward",
          "organizationalChartReward": "Sales Team Reward",
          level1Members: "Level 1 Members",
          organizationalChartMembers: " Chart Members",
          receivedReward: "Received Reward",
          emailAddress: "Email Address",
          password: "Password",
          confirmPassword: "Confirm Password",
          referralCode: "Referral Code",
          enterYourEmail: "Enter your email",
          enterYourPassword: "Enter your password",
          repeatYourPassword: "Repeat your password",
          enterReferralCode: "Enter referral code",
          acceptTerms: "I accept the terms and conditions and I am 18 years old",
          requestPasswordReset: "Request Password Reset",
          contactAdmin: "Contact Admin",
          signUp: "Sign Up",
          alreadyHaveAccount: "Already have an account?",
          login: "Login",
          invalidReferralCode: "Invalid referral code",
          userAlreadyExists: "User  already exists",
          completeAllFields: "Please complete all fields",
          google: "Google",
          telegram: "Telegram",
          password: "Password",
          enterYourEmail: "Enter your email",
          enterYourPassword: "Enter your password",
          login: "Login",
          signUp: "Sign Up",
          orLoginWith: "Or log in with",
          educationalMenu: "Educational Menu",
          close: "Close",
          educationalVideos: "Educational Videos",
          rewards: "Rewards",
          support: "Support",
          requestPasswordReset: "Request Password Reset",
          contactAdmin: "Contact Admin",
          forgotPassword: "Forgot your password?",
          dontHaveAccount: "Don't have an account?",
          welcome: "Welcome",
          welcomeBack: "Welcome back",
          buyToken: "Buy Token",
          register: "Register",
          buyTicket: "Buy Ticket",
          login: "Login",
          home: "Home",
          specialCommission: "10% special commission",
          referFriends: "Earn 10% commission by referring LX currency to your friends",
          or: "or",
          sharertl: "Share via RTL",
          shareltr: "Share via LTR",
          share: "Share",
          winners: "Winners",
          viewAll: "View All",
          myStats: "My Stats",
          level1Members: "Level 1 Members",
          level2Members: "Level 2 Members",
          receivedRewards: "Received Rewards",
          yourWallet: "Your Wallet",
          myTickets: "My Tickets",
          myTokens: "My Tokens",
          prizeWinners: "Prize Winners",
          verifyWithdrawalAddress: "Verify Withdrawal Address",
          inviteFriends: "Invite Friends",
          yourRewards: "Your Rewards",
          yourFriends: "Your Friends",
          editProfile: "Edit Profile",
          linkCopied: "Your link has been copied!",
          menu: "Menu",
          profile: "Profile",
          yourWallet: "Your Wallet",
          lottery: "Lottery",
          welcomeMessage: "Welcome to Lucky X",
          buyTicketMessage: "Buy a ticket to participate in the lottery",
          registerOrLoginMessage: "Register or log in to use the services",
          logout: "Logout",
          myReferralLink: "My Referral Link",
          myRewards: "My Rewards",
          myTeam: "My Team",
          myTickets: "My Tickets",
          deposit: "Deposit",
          withdraw: "Withdraw",
          transactions: "Transactions",
          participants: "Participants",
          incomePlan: "learn",
          aboutUs: "About Us",
          currentPrice: "Current Price",
          depositTransactions: "Deposit Transactions",
          withdrawTransactions: "Withdraw Transactions",
          status: "Status",
          amount: "Amount",
          successful: "Successful",
          deposits: "Deposits",
          lotteryPrize: "Lottery Prize",
          lotteryPrizeDescription: "Prize for this round of the lottery",
          viewWinners: "View Winners",
          join: "Join",
          userName: "User  Name",
          prize: "Prize",
          purchaseDate: "Purchase Date",
          userName: "User  Name",
          email: "Email",
          purchaseDate: "Purchase Date",
          noTicketsMessage: "No tickets purchased",
        }
      },
      ar: {
        translation: {
          openTelegramApp : "فتح تطبيق تيليجرام",
          contactUs : "اتصل بنا",
          "platform_intro": "اكتشف منصتنا لتعرف أكثر ولا تفوت الفرصة..!",
          "greeting": "مرحبا يا أصدقاء! اكتشفوا منصتنا! مع منصتنا، سيكون لديكم الوصول إلى كل المعلومات التي تحتاجونها لاتخاذ قرارات مدروسة. منصتنا هي مكان شامل لكل المعلومات التي تحتاجونها. فماذا تنتظرون؟ دعونا نبدأ!",
          "about_us": "عنَّا",
          "about_us_content": "لاكي إكس هي شركة بيع مباشر تنشط في مجال التسويق الشبكي والأسواق المالية وشركات التكنولوجيا، وقد تأسست في أواخر عام 2024. في لاكي إكس، هدفنا هو الاستثمار في الشركات التي تجعل حياة البشر أفضل وتساعد على حل المشكلات الكبيرة من خلال التكنولوجيا. نحن نستثمر في المشاريع على المدى الطويل وندرك أن النجاح يحتاج إلى جهد وتضحية ومثابرة. أحد آخر مشاريع لاكي إكس في عام 2025 هو العمل في الأسواق المالية، والذي حقق أعلى عوائد للمستخدمين في هذه الشبكة من خلال جهود الخدمات المالية للعملات الرقمية.",
          "team": "فريق لاكي إكس",
          "founder": "أرنويدا ناوسدا - المؤسس",
          "founder_info": "السيد أرنولدا ناوسدا حاصل على شهادة بكاليوس في الاقتصاد، وماجستير إدارة الأعمال في المالية، وماجستير إدارة الأعمال التنفيذي في الإدارة العامة الدولية، ولديه أدوار تنفيذية رفيعة في المالية، والاستثمار، وتطوير الأعمال في مجموعة واسعة من الشركات الدولية التي تغطي صناعات متنوعة مثل اللوجستيات، البناء، التجزئة، الآلات الثقيلة، الرياضة، معالجة المعادن، والخدمات المالية وإدارة الاستثمار. مستثمر ذو خبرة عملية في إدارة الاستثمار، مع استثمارات في الخدمات المالية، والعقارات، وشركات التكنولوجيا.",
          "product_roadmap": "خريطة طريق المنتج",
          "roadmap_content": "خريطة طريق المنتج توضح المسار الذي أمامنا، وتساعد الفرق على التخطيط وتعمل كدليل لتقديم المنتج.",
          "steps": "الخطوات",
          "step1": "الخطوة الأولى بحث المشروع",
          "step1_content": "بحث المشروع هو العمود الفقري لكل مشروع ناجح. يتضمن جمع المعلومات، تحديد الأهداف، وتحليل البيانات للتأكد من إمكانية تحقيق المشروع. بدون بحث مناسب، يمكن أن تفشل المشاريع بسبب نقص المعرفة.",
          "step2": "الخطوة الثانية ابتكار الأفكار",
          "step2_content": "أردت فقط أن أطرح فكرة جديدة لمشروعنا القادم. دعونا نبدأ بالصورة الكبيرة ثم ننتقل إلى التفاصيل. بهذه الطريقة يمكننا التأكد من أن رؤيتنا على المسار الصحيح وفي نفس الوقت نعمل على تنظيم الأمور.",
          "step3": "الخطوة الثالثة تصميم المسبق",
          "step3_content": "بس حبيت أشارك المسودة الأولية لتصميمي! رحت بأسلوب بسيط بلون مبهج. شو رأيكم؟ أنا جاهز لأية اقتراحات أو تعليقات. ما أقدر أصبر أشوف كيف رح تظهر وشو النجاح اللي رح تحققوا!",
          "step4": "خطوة رابعة التصميم النهائي",
          "step4_content": "بعد الكثير من التكرار والتغييرات اللانهائية، أخيرًا وصلنا للتصميم النهائي. هالتصميم أنيق وعصري ويعكس بشكل جيد جوهر اللي كنا ندور عليه. ما نقدر نتحمس أكثر لفكرة إطلاقه!",
          "step5": "خطوة خامسة تطوير المشروع",
          "step5_content": "إحنا حاليًا في عمق تطوير المشروع وكل شيء ماشي زي ما يلزم. عندنا خطة قوية وفريقنا يشتغل بكل جهد. لا يزال في شغلات لازم تسوى، بس إحنا متحمسين جدًا للي قاعد نبنيه.",
          "final_step": "الخطوة النهائية إطلاق المشروع",
          "final_step_content": "طيب يا جماعة، حان الوقت نطلق هالمشروع! ناقشنا فيه لأسابيع والحين وقت التنفيذ. كل القطع في مكانها، فلنبدأ نشتغل ونحول الفكرة للواقع!",
          "pricing_plan": "برنامج تسعيرنا",
          "pricing_content": "اخترنا باقات تتيح لكل أفراد المجتمع بدء إدارة مالية وقوة تحمل المخاطر الخاصة بهم.",
          "products_services": "منتجات وخدمات لاكي إكس",
          "product1": "المنتج الأول: عملة رقمية LX",
          "product1_content": "واحد من منتجات لاكي إكس هو الطرح الأولي لعملات LX اللي تنعرض للمستخدمين في باقات تتكون من ٥٠ ألف وحدة. كل باقة تُباع للمستخدمين بسعر ١٠٠ TRX، وما في أي قيود على المستخدمين بخصوص الاستثمار لشراء عملات LX.",
          "more_info": "للمزيد من المعلومات، اضغط على الرابط أدناه واقرأ خريطة طريق العملة الرقمية LX.",
          "product2": "المنتج الثاني: تذاكر قرعة لاكي إكس",
          "product2_content": "بقية منتجات لاكي إكس هي تذاكر قرعة تتوزع بين ٢٠ مشتري، وفي كل فترة يتم منح جائزتين نقديتين للمستخدمين؛ واحدة للرابح والأخرى لمعرّف الرابح. سعر التذاكر والجوائز يختلف في كل دورة.",
          "income_plan": "برنامج كسب المال لاكي إكس",
          "income_plan1": "الخطة ١: عمولة إحالة مباشرة ١٠٪",
          "income_plan1_content": "يا صديقي، في حسابك يوجد رابطين للإحالة، واحد على اليمين والثاني على اليسار، يمكنك من خلالها أن تعرّف أصدقائك على منتجات الشركة وكسب عمولة بلا حدود.",
          "income_plan2": "الخطة ٢: عمولة بناء الفريق ١٠٪",
          "income_plan2_content": "يا صديقي، فريق المبيعات الخاص بك يقسم إلى فريقين، يسار ويمين، ولتوازن حجم المبيعات، ستحصل على عمولة ١٠٪ من حجم مبيعات الفريق الأصغر، وراتبك اليومي من هذه الخطة هو ٢٠٠٠ TRX.",
          "welcome_content": "مرحباً بك في موقع تجارتنا! نحن نقدم أفضل البرامج الربحية في سوق التجارة. ابدأ باستخدام رابط الإحالة الآن!",
          welcomesite: "مرحبًا بكم في موقع لاكي X",
          title: "لاكي X",
          hopeBeWinner: "نتمنى أن تكون الفائز المحظوظ في هذه الدورة",
          unknown: "غير معروف",
          yourFriendstitle: "أصدقائك",
          "performanceSummary": "ملخص الأداء:",
          "rightSalesVolume": "حجم المبيعات اليمنى",
          "totalSalesVolume": "إجمالي حجم المبيعات",
          "leftSalesVolume": "حجم المبيعات اليسرى",
          "mySalesTeam": "فريقي",
          "date": "التاريخ",
          "balance": "التوازن",
          "rightPoints": "نقاط اليمين",
          "leftPoints": "نقاط اليسار",
          "saveRightPoints": "نقاط اليمين المحفوظة",
          "saveLeftPoints": "نقاط اليسار المحفوظة",
          "deposited": "تم الإيداع",
          "inProgress": "قيد التنفيذ",
          "noParent": "بدون والد",
          "you": "أنت",
          "noRef": "بدون معرف",
          "noName": "بدون اسم",
          "participateInLottery": "المشاركة في اليانصيب",
          "buyTicketDescription": "عزيزي، يتم إجراء سحب اليانصيب بين 20 شخصًا في كل دورة، وسعر كل تذكرة هو 100 ترون. جائزة الفائز هي 500 ترون، وجائزة المحيل للفائز هي أيضًا 100 ترون. في كل دورة، يمكنك شراء تذكرتين فقط، وبعد السحب، سيتم إيداع الجوائز في محفظتك. اشترِ تذكرة للمشاركة في اليانصيب.",
          "required": "ضروري",
          "maxTwoTickets": "يمكنك شراء حد أقصى من 2 تذكرة.",
          "invalidEmail": "البريد الإلكتروني غير صالح.",
          "emailMismatch": "البريد الإلكتروني المدخل لا يتطابق مع البريد الإلكتروني المخزن.",
          "purchaseSuccessful": "تم الشراء بنجاح.",
          "errorOccurred": "حدث خطأ. يرجى المحاولة مرة أخرى لاحقًا.",
          "ticketCountF": "عدد التذاكر",
          "thankYouForPurchase": "شكرًا لشرائك.",
          "tokensWillBeTransferred": "سيتم تحويل رموز LX إلى محفظتك خلال 24 ساعة. شكرًا لصبرك.",
          "buyTokenLX": "شراء رمز LX",
          "tokenDescription": "لقد قامت LuckyX بتعبئة عرض الرموز الأولية في حزم من 50000 وحدة، مع تسعير كل حزمة بـ 100 TRX. لا يوجد حد لعدد هذه الرموز التي يمكنك شراؤها. مقابل كل 5 ملايين وحدة مباعة، سيتم إضافة 10 TRX إضافية إلى سعر كل حزمة.",
          "enterTokenCount": "يرجى إدخال عدد الحزم.",
          "invalidWalletAddress": "عنوان المحفظة غير صالح.",
          "editProfileName": "يرجى تعديل اسم المستخدم الخاص بك في قسم الملف الشخصي.",
          "insufficientBalance": "رصيد غير كاف.",
          "cannotBuyMoreThanTwo": "لا يمكنك شراء أكثر من رمزين.",
          "tokenExpiredLoginAgain": "انتهت صلاحية الرمز، يرجى تسجيل الدخول مرة أخرى.",
          "errorOccurred": "حدث خطأ. يرجى المحاولة مرة أخرى لاحقًا.",
          "walletAddressTon": "عنوان المحفظة تون",
          "enterWalletAddress": "أدخل عنوان محفظتك.",
          "tokenCount": "عدد الرموز",
          "eachPack50k": "كل حزمة 50k",
          "pricePerPack": "سعر كل حزمة",
          "referralLink": "رابط الدعوة",
          "inviteTelegram": "دعوة عبر تيليجرام",
          "inviteTelegramDescription": "ستكسب المزيد من خلال الدعوة عبر تيليجرام",
          "shareRightTelegram": "مشاركة الكود الأيمن على تيليجرام",
          "shareLeftTelegram": "مشاركة الكود الأيسر على تيليجرام",
          "inviteSiteCodeRight": "دعوة رابط الموقع الأيمن",
          "inviteSiteCodeLeft": "دعوة رابط الموقع الأيسر",
          "copyLink": "نسخ الرابط",
          "directInviteReport": "تقرير الدعوة المباشرة",
          "organizationalChartReport": "تقرير الهيكل التنظيمي",
          "totalProfit": "إجمالي الربح",
          "showDirectTeam": "عرض الفريق المباشر",
          "showOrganizationalChart": "عرض الهيكل التنظيمي",
          "linkCopied": "تم نسخ الرابط الخاص بك!",
          "image": "الصورة",
          "assets": "الأصول",
          "notProvided": "لم يتم تقديمه",
          "noData": "لا توجد بيانات لعرضها.",
          "totalRewards": "إجمالي المكافآت",
          "dailyPerformance": "الأداء اليومي",
          "date": "التاريخ",
          "reward": "مكافأة",
          "noData": "لا توجد بيانات لعرضها.",
          "count": "العدد",
          "wallet": "المحفظة",
          "noTickets": "لا توجد تذاكر تم شراؤها.",
          "previousLotteryInfo": "معلومات السحب السابقة",
          "startDate": "تاريخ البدء",
          "endDate": "تاريخ الانتهاء",
          "winner": "الفائز",
          "ticketCount": "عدد التذاكر المشتراة",
          "viewAllWinners": "عرض جميع الفائزين",
          "allWinners": "جميع الفائزين",
          "name": "الاسم",
          "date": "التاريخ",
          "referrer": "المُعَرِف",
          "noReferrer": "لا يوجد",
          "fillAtLeastOneField": "يرجى ملء حقل واحد على الأقل لتحديث ملفك الشخصي.",
          "username": "اسم المستخدم",
          "newUsername": "اسم المستخدم الجديد",
          "newEmail": "البريد الإلكتروني الجديد",
          "avatar": "الصورة الرمزية",
          "updateProfile": "تحديث الملف الشخصي",
          "changePassword": "تغيير كلمة المرور",
          "changePasswordLabel": "تغيير كلمة المرور",
          "oldPassword": "كلمة المرور القديمة",
          "newPassword": "كلمة المرور الجديدة",
          "updatePassword": "تحديث كلمة المرور",
          "profileUpdated": "تم تحديث الملف الشخصي بنجاح",
          "profileUpdateSuccess": "تم تحديث معلومات ملفك الشخصي بنجاح.",
          "emailMismatch": "البريد الإلكتروني المدخل لا يتطابق مع البريد الإلكتروني المخزن.",
          "walletExists": "المحفظة موجودة بالفعل. إذا تم إدخال محفظة خاطئة، يرجى الاتصال بالدعم.",
          "invalidWallet": "يرجى إدخال عنوان محفظة ترون صالح.",
          "walletVerified": "تم التحقق من محفظتك بنجاح.",
          "verifyInstructions": "يرجى تقديم عنوان ترون الخاص بك وعنوان بريدك الإلكتروني للتحقق من سحب مكافأتك.",
          "enterEmail": "أدخل بريدك الإلكتروني",
          "enterWalletAddress": "أدخل عنوان محفظتك",
          "confirm": "تأكيد",
          "walletRegistered": "تم تسجيل محفظتك. إذا تم إدخال محفظة خاطئة، يرجى الاتصال بالدعم.",
          "invalidEmailOrPassword": "البريد الإلكتروني أو كلمة المرور غير صحيحة.",
          "blockchainServerError": "هناك مشكلة في خادم البلوكشين.",
          "walletAddress": "عنوان المحفظة",
          "minimum30trx": "حد أدنى 30 ترون",
          "fee": "الرسوم",
          "loadingWithdraw": "جارٍ تحميل السحب...",
          "minimumDeposit": "الحد الأدنى للإيداع هو 103 ترون.",
          "pleaseFillField": "يرجى ملء الحقل.",
          "depositError": "خطأ في الإيداع. يرجى المحاولة مرة أخرى.",
          "tokenExpired": "انتهت صلاحية الرمز، يرجى تسجيل الدخول مرة أخرى.",
          "minimumDepositAmount": "الحد الأدنى للإيداع: {{amount}} ترون",
          "loading": "جارٍ التحميل...",
          "deposit": "إيداع",
          "displayOrganizationalChart": "عرض الهيكل التنظيمي",
          "directInvitationReward": "مكافأة الدعوة المباشرة",
          "organizationalChartReward": "مكافأة فريق المبيعات",
          "level1Members": "أعضاء المستوى 1",
          "organizationalChartMembers": "أعضاء الهيكل التنظيمي",
          "receivedReward": "المكافأة المستلمة",
          emailAddress: "عنوان البريد الإلكتروني",
          password: "كلمة المرور",
          confirmPassword: "تأكيد كلمة المرور",
          referralCode: "رمز الإحالة",
          enterYourEmail: "أدخل بريدك الإلكتروني",
          enterYourPassword: "أدخل كلمة المرور الخاصة بك",
          repeatYourPassword: "كرر كلمة المرور الخاصة بك",
          enterReferralCode: "أدخل رمز الإحالة",
          acceptTerms: "أقبل الشروط والأحكام وأبلغ من العمر 18 عامًا",
          requestPasswordReset: "طلب إعادة تعيين كلمة المرور",
          contactAdmin: "الاتصال بالمسؤول",
          signUp: "تسجيل",
          alreadyHaveAccount: "هل لديك حساب؟",
          login: "الدخول",
          invalidReferralCode: "رمز الإحالة غير صالح",
          userAlreadyExists: "المستخدم موجود بالفعل",
          completeAllFields: "يرجى ملء جميع الحقول",
          google: "جوجل",
          telegram: "تيليجرام",
          password: "كلمة المرور",
          enterYourEmail: "أدخل بريدك الإلكتروني",
          enterYourPassword: "أدخل كلمة المرور الخاصة بك",
          orLoginWith: "أو تسجيل الدخول باستخدام",
          educationalMenu: "قائمة تعليمية",
          close: "إغلاق",
          educationalVideos: "فيديوهات تعليمية",
          rewards: "جوائز",
          support: "الدعم",
          requestPasswordReset: "اطلب إعادة تعيين كلمة المرور",
          contactAdmin: "الاتصال بالمسؤول",
          forgotPassword: "نسيت كلمة المرور؟",
          dontHaveAccount: "ليس لديك حساب؟",
          welcome: "أهلاً وسهلاً",
          welcomeBack: "مرحباً بعودتك",
          buyToken: "شراء توكن",
          register: "تسجيل",
          buyTicket: "شراء تذكرة",
          home: "الرئيسية",
          specialCommission: "10% عمولة خاصة",
          referFriends: "احصل على 10% عمولة من خلال إحالة عملة LX لأصدقائك",
          or: "أو",
          sharertl: "مشاركة من اليمين",
          shareltr: "مشاركة من اليسار",
          share: "مشاركة",
          winners: "الفائزون",
          viewAll: "عرض الكل",
          myStats: "إحصائياتي",
          level1Members: "أعضاء المستوى 1",
          level2Members: "أعضاء المستوى 2",
          receivedRewards: "المكافآت المستلمة",
          yourWallet: "محفظتك",
          myTickets: "تذاكري",
          myTokens: "توكناتي",
          prizeWinners: "الفائزون بالجوائز",
          verifyWithdrawalAddress: "تحقق من عنوان سحب المكافآت",
          inviteFriends: "دعوة الأصدقاء",
          yourRewards: "مكافآتك",
          yourFriends: "أصدقاؤك",
          editProfile: "تعديل الملف الشخصي",
          linkCopied: "تم نسخ الرابط الخاص بك!",
          menu: "القائمة",
          profile: "الملف الشخصي",
          yourWallet: "محفظتك",
          lottery: "اليانصيب",
          welcomeMessage: "مرحباً بك في لاكي إكس",
          buyTicketMessage: "اشترِ تذكرة للمشاركة في اليانصيب",
          registerOrLoginMessage: "سجل أو قم بتسجيل الدخول لاستخدام الخدمات",
          logout: "تسجيل الخروج",
          myReferralLink: "رابط الإحالة الخاص بي",
          myRewards: "مكافآتي",
          myTeam: "فريقي",
          myTickets: "تذاكري",
          deposit: "إيداع",
          withdraw: "سحب",
          transactions: "المعاملات",
          participants: "المشاركون",
          incomePlan: "تدريب",
          aboutUs: "معلومات عنا",
          currentPrice: "السعر الحالي",
          depositTransactions: "معاملات الإيداع",
          withdrawTransactions: "معاملات السحب",
          status: "الحالة",
          amount: "المبلغ",
          successful: "ناجح",
          deposits: "الإيداعات",
          lotteryPrize: "جائزة اليانصيب",
          lotteryPrizeDescription: "جائزة هذه الجولة من اليانصيب",
          viewWinners: "عرض الفائزين",
          join: "انضم",
          userName: "اسم المستخدم",
          prize: "جائزة",
          purchaseDate: "تاريخ الشراء",
          userName: "اسم المستخدم",
          email: "البريد الإلكتروني",
          purchaseDate: "تاريخ الشراء",
          noTicketsMessage: "لم يتم شراء أي تذاكر",

        }
      },
      tr: {
        translation: {
          openTelegramApp : "Telegram Uygulamasını Aç",
          contactUs : "Bize Ulaşın",
          "platform_intro": "Platformumuzu tanıyın, daha fazla bilgi edinin ve fırsatları kaçırmayın..!",
          "greeting": "Herkese merhaba! Platformumuzla tanışın! Platformumuz sayesinde, bilinçli kararlar almak için ihtiyaç duyduğunuz tüm bilgilere erişim sağlayacaksınız. Platformumuz, bilmeniz gereken her şey için tek durak noktanız. O halde neyi bekliyorsunuz? Hadi başlayalım!",
          "about_us": "Hakkımızda",
          "about_us_content": "Lucky X, ağ pazarlama ve finansal piyasalar ile teknoloji şirketleri alanında faaliyet gösteren bir doğrudan satış şirketidir ve 2024'ün sonlarında kurulmuştur. LuckyX'te amacımız, insanlığı daha iyi hale getiren ve teknolojinin sağladığı büyük sorunları çözen şirketlere yatırım yapmaktır. Uzun vadeli projelere yatırım yapıyoruz ve başarının özveri, adanmışlık ve azim gerektirdiğini anlıyoruz. Lucky X'in 2025'teki son projelerinden biri, dijital para finansal hizmetlerinin çabalarıyla bu ağın kullanıcılarına en fazla faydayı sağlayan finansal piyasalarda çalışmaktır.",
          "team": "Lucky X Ekibi",
          "founder": "Arnoldas Nauseda - Kurucu",
          "founder_info": "B.Ec., MBA Finans, EMBA Uluslararası Genel Yönetim, lojistik, inşaat, perakende, ağır makineler, spor, metal işleme, finansal hizmetler ve yatırım yönetimi gibi geniş bir uluslararası şirket yelpazesinde finans, yatırım ve iş geliştirme alanlarında üst düzey yönetici rolleri. Finansal hizmetler, gayrimenkul ve teknoloji şirketlerinde yatırımlara sahip, yatırım yönetiminde pratik deneyime sahip bir yatırımcı.",
          "product_roadmap": "Ürün yol haritası",
          "roadmap_content": "Bir ürün yol haritası, önünüzdeki yolu gösterir, ekiplerin plan yapmasına yardımcı olur ve ürünün teslimatını yönlendirir.",
          "steps": "Adımlar",
          "step1": "Proje Araştırması",
          "step1_content": "Proje araştırması, herhangi bir başarılı projenin belkemiğidir. Bilgi toplama, hedef belirleme ve projenin gerçekleştirilebilirliğini sağlamak için verileri analiz etme sürecini içerir. Doğru araştırma olmadan, projeler bilgi eksikliği nedeniyle başarısız olabilir.",
          "step2": "Çerçeve Fikri",
          "step2_content": "Gelecek projemiz için bir çerçeve fikri atmak istedim. Önce büyük resmi düşünelim, sonra detaylara inelim. Bu şekilde vizyonumuzun doğru yolda kalmasını sağlarken, aynı zamanda her şeyi netleştirmiş oluruz.",
          "step3": "Tasarım İlk Taslağı",
          "step3_content": "Tasarımımın ilk taslağını paylaşmak istedim! Minimalist bir görünüm tercih ettim ve biraz renk kattım. Ne düşünüyorsunuz? Her türlü öneriye ve geri bildirime açığım. Sonuçları görmek için sabırsızlanıyorum, her türlü başarıya ulaşacağımıza inanıyorum!",
          "step4": "Son Tasarım",
          "step4_content": "Birçok tekrar ve sonsuz ayarlamadan sonra, nihayet son tasarıma ulaştık. Şık, modern ve hedeflediğimiz özün tam anlamıyla yansımasını sağlıyor. Onu piyasaya sürmek için daha fazla heyecanlanamazdık!",
          "step5": "Proje Geliştirme",
          "step5_content": "Proje geliştirme sürecine derinlemesine dalmış durumdayız ve işler güzel gidiyor. Elimizde sağlam bir plan var ve ekibimiz tüm hızıyla çalışıyor. Hala yapılacak işler var ama inşa ettiğimiz şeyler için heyecanlıyız.",
          "final_step": "Proje Lansmanı",
          "final_step_content": "Tamam arkadaşlar, bu projeyi hayata geçirme zamanı! Haftalardır bunun hakkında konuşuyoruz ve şimdi başlama zamanı. Tüm parçalar yerinde, o yüzden hadi işe koyulalım ve bunu gerçekleştirelim!",
          "pricing_plan": "Fiyat Planımız",
          "pricing_content": "Toplumdaki herkesin kendi finansal yönetimlerini ve risk alma güçlerini başlatabilmeleri için paketler seçtik.",
          "products_services": "Lucky X Ürünü",
          "product1": "Ürün 1: LX Dijital Para Birimi",
          "product1_content": "Lucky X'in ürünlerinden biri, kullanıcılara 50K paketler halinde sunulan LX token'ın ilk sürümüdür. Her paket kullanıcıya 100TRX fiyatla sunulmakta ve kullanıcıların LX token satın alımında herhangi bir kısıtlaması yoktur.",
          "more_info": "Daha fazla bilgi için, aşağıdaki bağlantıya tıklayın ve LX dijital para birimi yol haritasını okuyun.",
          "product2": "Ürün 2: Lucky X Piyango Bileti",
          "product2_content": "Lucky X'in diğer ürünleri, 20 alıcı arasında çekilen piyango bileti olup, her dönemde kullanıcılara iki nakit ödül verilmektedir; ödüllerden biri kazanana, diğeri ise kazananın temsilcisine verilmektedir. Bilet ve ödül miktarları her dönemde değişiklik göstermektedir.",
          "income_plan": "Lucky X Gelir Üretim Planı",
          "income_plan1": "Plan 1: Doğrudan Referans Komisyonu %10",
          "income_plan1_content": "Sevgili arkadaşım, kullanıcı hesabında sağ ve sol olmak üzere iki referans bağlantısı var. Şirketin ürünlerini arkadaşlarına sonsuz sayıda tanıtabilir ve komisyon kazanabilirsin.",
          "income_plan2": "Plan 2: Takım Oluşturma Komisyonu %10",
          "income_plan2_content": "Sevgili arkadaşım, satış ekibin sol ve sağ olmak üzere iki satış ekibine ayrılmıştır. Satış hacmini dengelemek için, küçük ekibin satış hacminden %10 komisyon alırsın ve bu plandan günlük gelirlerin 2000 TRX'dir.",
          "welcome_content": "Ticaret sitemize hoş geldin! Ticaret piyasasında en iyi, kârlı planları sunuyoruz. Referans bağlantısını kullanmaya başla!",
          welcomesite: "Şanslı X web sitesine hoş geldiniz",
          title: "Şanslı X",
          hopeBeWinner: "Bu çekilişte şanslı kazanan olmanızı umuyoruz.",
          unknown: "bilinmeyen",
          yourFriendstitle: "Dostlariniz",
          "performanceSummary": "Performans Özeti:",
          "rightSalesVolume": "Sağ Satış Hacmi",
          "totalSalesVolume": "Toplam Satış Hacmi",
          "leftSalesVolume": "Sol Satış Hacmi",
          "mySalesTeam": "Satış Takımım",
          "date": "Tarih",
          "balance": "Bakiye",
          "rightPoints": "Sağ Puanlar",
          "leftPoints": "Sol Puanlar",
          "saveRightPoints": "Kaydedilen Sağ Puanlar",
          "saveLeftPoints": "Kaydedilen Sol Puanlar",
          "deposited": "Yatırıldı",
          "inProgress": "Devam Ediyor",
          "noParent": "Ebeveyn Yok",
          "you": "Sen",
          "noRef": "Referans Yok",
          "noName": "İsim Yok",
          "participateInLottery": "Piyangoya Katıl",
          "buyTicketDescription": "Sevgili arkadaşım, her çekiliş turu 20 kişi arasında yapılmaktadır ve her biletin fiyatı 100 TRX'tir. Kazananın ödülü 500 TRX, referansın ödülü ise 100 TRX'tir. Her turda yalnızca iki bilet satın alabilirsiniz ve çekilişten sonra ödüller cüzdanınıza yatırılacaktır. Lotaryaya katılmak için bilet satın alın;.",
          "required": "Gerekli",
          "maxTwoTickets": "Maksimum 2 bilet alabilirsiniz.",
          "invalidEmail": "Geçersiz e-posta.",
          "emailMismatch": "Girilen e-posta, kaydedilen e-posta ile eşleşmiyor.",
          "purchaseSuccessful": "Başarıyla satın alındı.",
          "ticketCountF": "Bilet Sayısı",
          "thankYouForPurchase": "Satışınız için teşekkür ederiz.",
          "tokensWillBeTransferred": "LX tokenleri 24 saat içinde cüzdanınıza aktarılacaktır. Sabır gösterdiğiniz için teşekkür ederiz.",
          "buyTokenLX": "LX Token Satın Al",
          "tokenDescription": "LuckyX, ilk token teklifini 50.000 birimlik paketler halinde sunmuştur ve her paketin fiyatı 100 TRX'dir. Bu tokenlerden satın alırken herhangi bir sınırlama yoktur. Her 5 milyon birim satıldığında, her paketin fiyatına 10 TRX eklenir.",
          "enterTokenCount": "Lütfen paket sayısını girin.",
          "invalidWalletAddress": "Geçersiz cüzdan adresi.",
          "editProfileName": "Lütfen kullanıcı adınızı profil bölümünden düzenleyin.",
          "insufficientBalance": "Yetersiz bakiye.",
          "cannotBuyMoreThanTwo": "İki tokenden fazla satın alamazsınız.",
          "tokenExpiredLoginAgain": "Token süresi doldu, lütfen tekrar giriş yapın.",
          "errorOccurred": "Bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
          "walletAddressTon": "Ton wallet Adresi",
          "enterWalletAddress": "Cüzdan adresinizi girin.",
          "tokenCount": "Token Sayısı",
          "eachPack50k": "Her paket 50k",
          "pricePerPack": "Paket başına fiyat",
          "referralLink": "Referans Linki",
          "inviteTelegram": "Telegram ile Davet Et",
          "inviteTelegramDescription": "Telegram ile davet ederek daha fazla kazanacaksınız",
          "shareRightTelegram": "Sağ kodu Telegram'da paylaş",
          "shareLeftTelegram": "Sol kodu Telegram'da paylaş",
          "inviteSiteCodeRight": "Site sağ kodunu davet et",
          "inviteSiteCodeLeft": "Site sol kodunu davet et",
          "copyLink": "Bağlantıyı Kopyala",
          "directInviteReport": "Doğrudan Davet Raporu",
          "organizationalChartReport": "Organizasyon Şeması Raporu",
          "totalProfit": "Toplam Kazanç",
          "showDirectTeam": "Doğrudan Takımı Göster",
          "showOrganizationalChart": "Organizasyon Şemasını Göster",
          "linkCopied": "Bağlantınız kopyalandı!",
          "image": "Görüntü",
          "assets": "Varlıklar",
          "notProvided": "Sağlanmadı",
          "noData": "Gösterilecek veri yok.",
          "myRewards": "Ödüllerim",
          "totalRewards": "Toplam Ödüller",
          "dailyPerformance": "Günlük Performans",
          "date": "Tarih",
          "reward": "Ödül",
          "noData": "Gösterilecek veri yok.",
          "count": "Adet",
          "wallet": "Cüzdan",
          "noTickets": "Satın alınmış bilet yok.",
          "previousLotteryInfo": "Önceki Çekiliş Bilgileri",
          "startDate": "Başlangıç Tarihi",
          "endDate": "Bitiş Tarihi",
          "winner": "Kazanan",
          "ticketCount": "Satın Alınan Bilet Sayısı",
          "viewAllWinners": "Tüm Kazananları Görüntüle",
          "allWinners": "Tüm Kazananlar",
          "name": "İsim",
          "date": "Tarih",
          "referrer": "Referans",
          "noReferrer": "Yok",
          "fillAtLeastOneField": "Profilinizi güncellemek için lütfen en az bir alanı doldurun.",
          "username": "Kullanıcı Adı",
          "newUsername": "Yeni Kullanıcı Adı",
          "newEmail": "Yeni E-posta",
          "avatar": "Avatar",
          "updateProfile": "Profili Güncelle",
          "changePassword": "Şifre Değiştir",
          "changePasswordLabel": "Şifre Değiştir",
          "oldPassword": "Eski Şifre",
          "newPassword": "Yeni Şifre",
          "updatePassword": "Şifreyi Güncelle",
          "profileUpdated": "Profil başarıyla güncellendi.",
          "profileUpdateSuccess": "Profil bilgilerinizi başarıyla güncellediniz.",
          "invalidEmailOrPassword": "Geçersiz e-posta veya şifre.",
          "walletRegistered": "Cüzdanınız kaydedildi. Yanlış bir cüzdan girildiyse, lütfen destekle iletişime geçin.",
          "emailMismatch": "Girilen e-posta, kayıtlı e-posta ile eşleşmiyor.",
          "walletExists": "Cüzdan zaten mevcut. Yanlış bir cüzdan girildiyse, lütfen destekle iletişime geçin.",
          "invalidWallet": "Lütfen geçerli bir TRON cüzdan adresi girin.",
          "walletVerified": "Cüzdanınız başarıyla doğrulandı.",
          "verifyInstructions": "Ödül çekiminizi doğrulamak için lütfen TRON adresinizi ve e-posta adresinizi sağlayın.",
          "enterEmail": "E-posta adresinizi girin",
          "enterWalletAddress": "Cüzdan adresinizi girin",
          "confirm": "Onayla",
          "blockchainServerError": "Blockchain sunucusunda bir sorun var.",
          "walletAddress": "Cüzdan Adresi",
          "minimum30trx": "Minimum 30 TRX",
          "fee": "Ücret",
          "loadingWithdraw": "Çekim yükleniyor...",
          "minimumDeposit": "Minimum depozito 103 TRX'tir.",
          "pleaseFillField": "Lütfen alanı doldurun.",
          "depositError": "Yatırımda hata. Lütfen tekrar deneyin.",
          "tokenExpired": "Token süresi doldu, lütfen tekrar giriş yapın.",
          "minimumDepositAmount": "Minimum depozito: {{amount}} TRX",
          "loading": "Yükleniyor...",
          "deposit": "Yatır",
          "displayOrganizationalChart": "Ağaç Yapısını Göster",
          "directInvitationReward": "Doğrudan Davet Ödülü",
          "organizationalChartReward": "Satış Takımı Ödülü",
          "level1Members": "Seviye 1 Üyeleri",
          "organizationalChartMembers": "Organizasyon Şeması Üyeleri",
          "receivedReward": "Alınan Ödül",
          emailAddress: "E-posta Adresi",
          password: "Şifre",
          confirmPassword: "Şifreyi Onayla",
          referralCode: "Referans Kodu",
          enterYourEmail: "E-postanızı girin",
          enterYourPassword: "Şifrenizi girin",
          repeatYourPassword: "Şifrenizi tekrar girin",
          enterReferralCode: "Referans kodunu girin",
          acceptTerms: "Şartları kabul ediyorum ve 18 yaşındayım",
          requestPasswordReset: "Şifre sıfırlama isteği",
          contactAdmin: "Yönetici ile iletişime geç",
          signUp: "Kayıt Ol",
          alreadyHaveAccount: "Zaten bir hesabınız var mı?",
          login: "Giriş Yap",
          invalidReferralCode: "Geçersiz referans kodu",
          userAlreadyExists: "Kullanıcı zaten mevcut",
          completeAllFields: "Lütfen tüm alanları doldurun",
          google: "Google",
          telegram: "Telegram",
          password: "Şifre",
          enterYourEmail: "E-postanızı girin",
          enterYourPassword: "Şifrenizi girin",
          login: "Giriş Yap",
          signUp: "Kayıt Ol",
          orLoginWith: "Ya da ile giriş yap",
          educationalMenu: "Eğitim Menüsü",
          close: "Kapat",
          educationalVideos: "Eğitim Videoları",
          rewards: "Ödüller",
          support: "Destek",
          requestPasswordReset: "Şifre sıfırlama isteyin",
          contactAdmin: "Yönetici ile iletişime geç",
          forgotPassword: "Şifrenizi unuttunuz mu?",
          dontHaveAccount: "Hesabınız yok mu?",
          welcome: "Hoş geldiniz",
          welcomeBack: "Tekrar hoş geldiniz",
          buyToken: "Token Al",
          register: "Kayıt Ol",
          buyTicket: "Bilet Al",
          login: "Giriş Yap",
          home: "Ana Sayfa",
          specialCommission: "10% özel komisyon",
          referFriends: "Arkadaşlarınıza LX para birimini tanıtarak %10 komisyon kazanın",
          or: "veya",
          sharertl: "sola paylaş",
          shareltr: "sağa paylaş",
          share: "Paylaş",
          winners: "Kazananlar",
          viewAll: "Hepsini Gör",
          myStats: "İstatistiklerim",
          level1Members: "Seviye 1 Üyeleri",
          level2Members: "Seviye 2 Üyeleri",
          receivedRewards: "Alınan Ödüller",
          yourWallet: "Cüzdanınız",
          myTickets: "Biletlerim",
          myTokens: "Tokenlerim",
          prizeWinners: "Ödül Kazananları",
          verifyWithdrawalAddress: "Çekim adresini doğrula",
          inviteFriends: "Arkadaşları Davet Et",
          yourRewards: "Ödülleriniz",
          yourFriends: "Arkadaşlarınız",
          editProfile: "Profili Düzenle",
          linkCopied: "Bağlantınız kopyalandı!",
          menu: "Menü",
          profile: "Profil",
          yourWallet: "Cüzdanınız",
          lottery: "Piyango",
          welcomeMessage: "Lucky X'e hoş geldiniz",
          buyTicketMessage: "Piyangoya katılmak için bilet satın alın",
          registerOrLoginMessage: "Hizmetleri kullanmak için kaydolun veya giriş yapın",
          logout: "Çıkış Yap",
          myReferralLink: "Referans Linkim",
          myRewards: "Ödüllerim",
          myTeam: "Takımım",
          myTickets: "Biletlerim",
          deposit: "Yatır",
          withdraw: "Çek",
          transactions: "İşlemler",
          participants: "Katılımcılar",
          incomePlan: "تدريب",
          aboutUs: "Hakkımızda",
          currentPrice: "Güncel Fiyat",
          depositTransactions: "Yatırma İşlemleri",
          withdrawTransactions: "Çekim İşlemleri",
          status: "Durum",
          amount: "Tutar",
          successful: "Başarılı",
          deposits: "Yatırımlar",
          successful: "Başarılı",
          lotteryPrize: "Piyango Ödülü",
          lotteryPrizeDescription: "Bu tur piyango ödülü",
          viewWinners: "Kazananları Görüntüle",
          join: "Katıl",
          userName: "Kullanıcı Adı",
          prize: "Ödül",
          purchaseDate: "Satın Alma Tarihi",
          userName: "Kullanıcı Adı",
          email: "E-posta",
          purchaseDate: "Satın Alma Tarihi",
          noTicketsMessage: "Hiç bilet satın alınmadı"
        }
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;