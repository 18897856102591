import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Notification from '../components/Notification';
import EmailIcon from '@mui/icons-material/Email';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Botmenu from "../components/Botmenu";
import GroupIcon from '@mui/icons-material/Group';
import Config from '../Config';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const SIgnUp = () => {
    const { t } = useTranslation(); // Use the translation hook
    const navigate = useNavigate();
    const [showLogin, setShowLogin] = useState(true);
    const [showSignUp, setShowSignUp] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [timer, setTimer] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const toggleDropdown = () => {
        setIsOpenMenu(!isOpenMenu);
    };

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setIsButtonDisabled(false);
        setTimer(null);
    };

    const handleSendEmail = () => {
        setIsButtonDisabled(true);
        setTimer(60);
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(intervalId);
            setIsButtonDisabled(false);
            setTimer(null);
        }, 60000);
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [error, setError] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [isChecked, setIsChecked] = useState(false); // State for checkbox
    const [isReadOnly, setIsReadOnly] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const referralFromUrl = params.get('referral');

        if (referralFromUrl) {
            setReferralCode(referralFromUrl);
            setIsReadOnly(true); 
        } else {
            setIsReadOnly(false);
        }
    }, []);

    const Signupp = (e) => {
        e.preventDefault();

        if (!email || !password || !referralCode) {
            setNotificationMessage(t('completeAllFields')); 
            setShowNotification(true);
            setTimeout(() => {
                setShowNotification(false);
            }, 3000);
            return;
        }

        if (!isChecked) {
            setError(t('acceptTerms')); 
            return;
        }

        if (password !== confirmPassword) {
            alert(t('passwordMismatch')); 
            return;
        }

        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        formData.append('referral', referralCode);

        axios.post(`${Config.apiurl}/register`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const code = response.data.code;
                setError(code);
                if (code === 1) {
                    setNotificationMessage(t('accountCreated')); 
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 3000);
                    localStorage.setItem('userData', JSON.stringify(response.data.user));
                    localStorage.setItem('token', response.data.user.token);
                    window.location.href = '/profile';

                } else if (response.data.error === 144) {
                    setNotificationMessage(t('invalidReferralCode')); 
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 3000);
                } else if (response.data.error === 140) {
                    setNotificationMessage(t('userAlreadyExists'));
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 3000);
                } else {
                    setNotificationMessage(response.data.error);
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 3000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const [hideReferralCodeInput, setHideReferralCodeInput] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const referralCodeFromUrl = urlParams.get('referralCode');
        if (referralCodeFromUrl) {
            setReferralCode(referralCodeFromUrl);
            setHideReferralCodeInput(true);
        }
    }, []);

    return (
        <div className="signup">
            <Notification message={notificationMessage} open={showNotification} />
            <section>
                <div className="menu__top__login columns is-flex is-justify-content-space-between mt-1 px-1">
                    <button onClick={toggleDropdown} className="dropdown-button">
                        {isOpenMenu ? t('close') : t('educationalMenu')} 
                    </button>

                    {isOpenMenu && (
                        <div className="show_menu">
                            <div className="column is-12-mobile ">
                                <Link to="/educational-videos">{t('educationalVideos')}</Link> 
                            </div>
                            <div className="column is-12-mobile ">
                                <Link to="/rewards">{t('rewards')}</Link>
                            </div>
                            <div className="column is-12-mobile ">
                                <Link to="/support">{t('support')}</Link> 
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <section className='signup__bod'>
                <div className="google_telegram has-text-centered is-flex">
                    <Link to={'#'} style={{ marginLeft: '5px' }}>
                        <button>
                            <img src="../assets/icon/google.png" width={20} alt="" className="ml-2" />
                            <p>{t('google')}</p>
                        </button>
                    </Link>
                    <Link to={"https://t.me/TrainingLuckyCchanel"}>
                        <button>
                            <img src="../assets/icon/telegram.png" width={20} className="ml-2" alt="" />
                            <p>{t('telegram')}</p> 
                        </button>
                    </Link>
                </div>
                <div className="text has-text-centered mt-4">
                    <span className="line"></span>
                    {t('orLoginWith')} 
                    <span className="line"></span>
                </div>

                <div className="login-form mt-6 px-2">
                    <div className="input-container columns">
                        <div className="column lab is-2 is-2-mobile">
                            <EmailIcon className="iconm" />
                        </div>
                        <div className="column">
                            <label className="input-label">{t('emailAddress')}</label> 
                            <input
                                className="input-field"
                                type="text"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                placeholder={t('enterYourEmail')} 
                            />
                        </div>
                    </div>

                    <div className="input-container columns">
                        <div className="column is-2 is-2-mobile lab">
                            <VpnKeyIcon className="iconm" />
                        </div>
                        <div className="column">
                            <label className="input-label">{t('password')}</label> 
                            <input
                                className="input-field"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                placeholder={t('enterYourPassword')}
                            />
                        </div>
                        <div className="column is-2 is-2-mobile lab" onClick={togglePasswordVisibility}>
                            <RemoveRedEyeIcon className="iconm" style={{ cursor: 'pointer' }} />
                        </div>
                    </div>

                    <div className="input-container columns">
                        <div className="column is-2 is-2-mobile lab">
                            <VpnKeyIcon className="iconm" />
                        </div>
                        <div className="column">
                            <label className="input-label">{t('confirmPassword')}</label> 
                            <input
                                className="input-field"
                                type={showPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                placeholder={t('repeatYourPassword')} 
                            />
                        </div>
                        <div className="column is-2 is-2-mobile lab" onClick={togglePasswordVisibility}>
                            <RemoveRedEyeIcon className="iconm" style={{ cursor: 'pointer' }} />
                        </div>
                    </div>

                    <div className="input-container columns">
                        {!hideReferralCodeInput && (
                            <>
                                <div className="column is-2 is-2-mobile lab">
                                    <GroupIcon className="iconm" />
                                </div>
                                <div className="column">
                                    <label className="input-label">{t('referralCode')}</label> 
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={referralCode}
                                        placeholder={t('enterReferralCode')} 
                                        onChange={e => setReferralCode(e.target.value)}
                                        readOnly={isReadOnly}
                                    />
                                </div>
                            </>
                        )}
                        {hideReferralCodeInput && (
                            <input
                                className="input-field"
                                type="hidden"
                                value={referralCode}
                            />
                        )}
                    </div>

                    <div className="columns">
                        <div className="column is-flex">
                            <input
                                type="checkbox"
                                id="terms"
                                checked={isChecked}
                                className="custom-checkbox"
                                onChange={() => setIsChecked(!isChecked)}
                            />
                            <label className="input-labell mr-3">{t('acceptTerms')} {/* Use translation */}</label>
                        </div>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </div>

                    <div className={`modal ${isOpen ? "is-active" : ""}`}>
                        <div className="modal-background" onClick={handleCloseModal} />
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title is-size-6 has-text-light">{t('requestPasswordReset')}</p> {/* Use translation */}
                                <button
                                    className="delete"
                                    aria-label="close"
                                    onClick={handleCloseModal}
                                    style={{ padding: 10 }}
                                />
                            </header>
                            <section className="modal-card-body">
                                <a href="https://t.me/ownerluckyX" style={{ color: '#000' }}>
                                    <button
                                        onClick={handleSendEmail}
                                        className="button is-primary"
                                        disabled={isButtonDisabled}
                                        style={{ display: 'block', position: 'relative', top: 0, left: 0, padding: 20 }}
                                    >
                                        {t('contactAdmin')} 
                                    </button>
                                </a>
                            </section>
                        </div>
                    </div>

                    <div className="is-flex is-justify-content-space-between">
                        <button
                            className="mb-4"
                            onClick={Signupp}
                            style={{ backgroundColor: "#2C2F33" }}
                        >
                            {t('signUp')} 
                        </button>
                    </div>

                    <div className="mt-3 has-text-centered">
                        <p className="login__to__signUp">
                            {t('alreadyHaveAccount')} 
                            <Link to={"/Login"} className="mr-2">
                                {t('login')}
                            </Link>
                        </p>
                    </div>
                </div>
            </section>

            <section>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </section>
        </div>
    );
};

export default SIgnUp;