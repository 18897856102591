import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import axios from 'axios';
import Notification from '../components/Notification';
import MobileHeader from '../components/MobileHeader';
import Botmenu from '../components/Botmenu';
import { Tree } from 'react-d3-tree';
import Config from "../Config";
import { useTranslation } from 'react-i18next';

const Chart = () => {
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [data, setData] = useState(false);
    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'fa' || i18n.language === 'ar';

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setToken(userDataJson.token);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get(`${Config.apiurl}/user/tree`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then(async response => {
                const { parent, user, sub_left, sub_right } = response.data;
                const referralData = {
                    name: t('you'),
                    children: [
                        {
                            name: sub_left ? sub_left.name : t('noRef'),
                            children: await fetchChildren(sub_left ? sub_left.id : null),
                        },
                        {
                            name: sub_right ? sub_right.name : t('noRef'), 
                            children: await fetchChildren(sub_right ? sub_right.id : null),
                        },
                    ],
                };
                setData(referralData);
            })
            .catch(error => {
                console.error(error);
            });
        }
    }, [token]);

    const fetchChildren = async (userId) => {
        try {
            const response = await axios.get(`${Config.apiurl}/user/subtree`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: { user_id: userId },
            });
            const { user, sub_left, sub_right } = response.data;
            const children = [];

            if (sub_left) {
                children.push({
                    name: sub_left.name ? sub_left.name : t('noName'), 
                    children: await fetchChildren(sub_left.id),
                });
            }

            if (sub_right) {
                children.push({
                    name: sub_right.name ? sub_right.name : t('noName'),
                    children: await fetchChildren(sub_right.id),
                });
            }

            return children;
        } catch (error) {
            console.error(error);
        }
    };

    const NodeLabel = ({ node }) => {
        return (
            <div>
                {node && <span style={{ marginTop: '-1em' }}>{node.name}</span>}
                {node?.attributes?.deposit && (
                    <button style={{ borderRadius: '50%', padding: '5px', fontSize: '12px' }}>
                        <span style={{ fontSize: '16px' }}>&#33;</span>
                        {node.attributes.deposit}
                    </button>
                )}
            </div>
        );
    };

    return (
        <div className={isRtl? 'rtl' : 'ltr'}>
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section className='ref_section'>
                    <div className="container px-4 mt-4">
                        <div style={{ height: '100vh', width: '100vw' }} id='tree' className='container ref_page'>
                            {data ?
                                <Tree
                                    data={data}
                                    orientation="vertical"
                                    translate={{
                                        x: window.innerWidth / 3,
                                        y: window.innerHeight / 3,
                                    }}
                                    nodeLabelComponent={NodeLabel}
                                />
                                :
                                <div style={{
                                    height: '100vh',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: 30,
                                    fontWeight: 'bold',
                                    color: '#000'
                                }}>{t('loading')}...</div>
                            }
                        </div>
                    </div>
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </div>
    );
};

export default Chart;