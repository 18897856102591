import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import axios from 'axios';
import Notification from '../components/Notification';
import MobileHeader from '../components/MobileHeader';
import Botmenu from '../components/Botmenu';
import AddIcon from '@mui/icons-material/Add';
import Config from "../Config";
import { useTranslation } from 'react-i18next'; // اضافه کردن import برای ترجمه

const ReferralPage = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refferal, setRefferal] = useState({ parents1: [], parents2: [] });
  const [openRows, setOpenRows] = useState({});

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const userDataJson = JSON.parse(storedUserData);
      setUserData(userDataJson);
      setToken(userDataJson.token);
    }
  }, []);

  useEffect(() => {
    if (token) {
      setLoading(true); 
      axios.get(`${Config.apiurl}/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (response.data.code === 1) {
            const userData = response.data.user;
            localStorage.setItem('userData', JSON.stringify(userData));
            setUserData(userData);
            setToken(userData.token);
          } else {
            handleTokenChanged();
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false); 
        });
    }
  }, [token]);

  const handleTokenChanged = () => {
    setIsOpen(true);
    setNotificationMessage(t('tokenExpired')); // "توکن منقضی شده است، دوباره وارد شوید"
    setShowNotification(true);
    setTimeout(() => {
      localStorage.removeItem('userData');
      navigate('/login', { replace: true });
    }, 3000);
  };

  useEffect(() => {
    if (token) {
      setLoading(true);
      axios.get(`${Config.apiurl}/user/myteam`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          const refferalData = response.data;
          setRefferal(refferalData);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false); // End loading
        });
    }
  }, [token]);

  const toggleRow = (index) => {
    setOpenRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'fa' || i18n.language === 'ar';

  return (
    <div className={isRtl? 'rtl' : 'ltr'}>
      <Notification message={notificationMessage} open={showNotification} />
      <header>
        <div className="desktop_header">
          <Header />
        </div>
        <div className="mobile_header">
          <MobileHeader />
        </div>
      </header>

      <main>
        <section className='ref_section'>
          <div className="container px-4 mt-4">
            <h3 className='has-text-centered mt-5 mb-4'>{t("yourFriendstitle")}</h3>
            {loading ? (
              <div className="loading has-text-centered">{t('loading')}...</div> // "در حال بارگذاری..."
            ) : (
              <>
                <div className="column table__sec">
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th className="has-text-right">{t('image')}</th> 
                        <th className="has-text-centered">{t('name')}</th> 
                        <th className="has-text-centered">{t('assets')}</th>
                        <th className="has-text-centered">{t('reward')}</th> 
                      </tr>
                    </thead>
                    <tbody>
                      {refferal ?
                        <>
                          {refferal.parents1.map((item, index) => (
                            <tr key={item.id}>
                              <td className="has-text-centered">
                                <img  src={`${Config.baseurl}/${item.avatar}`} alt={item.name} />
                              </td>
                              <td className="has-text-centered"><p>{item.name ? item.name : t('notProvided')}</p></td> 
                              <td className="has-text-centered"><p>{item.balance}Trx</p></td>
                              <td className="has-text-centered"><p>{item.reward}Trx</p></td>
                            </tr>
                          ))}
                        </>
                        :
                        <tr>
                          <td>{t('noData')}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </section>
      </main>

      <footer>
        <nav className="mobile-navbar">
          <Botmenu />
        </nav>
      </footer>
    </div>
  );
};

export default ReferralPage;