import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import Notification from "../components/Notification";
import MobileHeader from "../components/MobileHeader";
import Botmenu from "../components/Botmenu";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useTranslation } from "react-i18next";

export default function BuyToken() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const [token, setToken] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false); 
    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'fa' || i18n.language === 'ar';

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                });
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage(t('tokenExpired')); 
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    const [tokenBuy, setTokenBuy] = useState({});

    useEffect(() => {
        if (userData.token) {
            axios.get('https://luckyx.cloud/api/v2/user/tokenprice', {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                }
            })
                .then(response => {
                    setTokenBuy(response.data.tokenprice);
                });
        }
    }, [userData.token]);

    const isValidWalletAddress = (wallet) => {
        const walletRegex = /^[a-zA-Z0-9]/; 
        return walletRegex.test(wallet);
    };

    return (
        <div className={isRtl? 'rtl' : 'ltr'}>
            <Notification message={notificationMessage} open={showNotification} />
            {showSuccessModal && (
                <div className="modal is-active">
                    <div className="modal-background" onClick={() => setShowSuccessModal(false)}></div>
                    <div className="modal-content">
                        <div className="box">
                            <h2 className="title has-text-centered">{t('thankYouForPurchase')}</h2> 
                            <p>{t('tokensWillBeTransferred')}</p> 
                            <button className="button is-primary" onClick={() => setShowSuccessModal(false)}>{t('close')}</button> 
                        </div>
                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={() => setShowSuccessModal(false)}></button>
                </div>
            )}
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section>
                    <div className="container lotery_join_page mt-3">
                        <div className="columns join_lot">
                            <div className="column is-5">
                                <h2 className="title has-text-centered">{t('buyTokenLX')}</h2>
                                <p style={{ textAlign: 'justify' }}>
                                    {t('tokenDescription')} 
                                </p>
                                <Formik
                                    initialValues={{ wallet: userData.wallet || '', tokens: '' }}
                                    validate={(values) => {
                                        const errors = {};
                                        if (!values.tokens) {
                                            errors.tokens = t('enterTokenCount'); 
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(true);

                                        if (!isValidWalletAddress(values.wallet)) {
                                            setNotificationMessage(t('invalidWalletAddress')); 
                                            setShowNotification(true);
                                            setTimeout(() => {
                                                setShowNotification(false);
                                            }, 3000);
                                            setSubmitting(false);
                                            return;
                                        }

                                        if (userData.name === "unknown" || userData.name === null) {
                                            setNotificationMessage(t('editProfileName')); 
                                            setNotificationMessage(t('editProfileName')); 
                                            setShowNotification(true);
                                            setTimeout(() => {
                                                setShowNotification(false);
                                                navigate('/setting', { replace: true });
                                            }, 3000);
                                            setSubmitting(false);
                                            return;
                                        }

                                        const formData = new FormData();
                                        formData.append('count', parseInt(values.tokens)); 
                                        formData.append('wallet', values.wallet); 

                                        const token = userData.token; 
                                        axios.post('https://luckyx.cloud/api/v2/user/buytoken', formData, {
                                            headers: {
                                                Authorization: `Bearer ${token}`,
                                                'Content-Type': 'multipart/form-data',
                                            },
                                        })
                                            .then((response) => {
                                                if (response.data.code === 586) {
                                                    setNotificationMessage(t('insufficientBalance')); 
                                                    setShowNotification(true);
                                                } else if (response.data.code === 404) {
                                                    setNotificationMessage(t('cannotBuyMoreThanTwo')); 
                                                    setShowNotification(true);
                                                } else if (response.data.code === 1) {
                                                    setShowSuccessModal(true); 
                                                    setTimeout(() => {
                                                        setShowSuccessModal(false);
                                                        navigate('/', { replace: true });
                                                    }, 10000);
                                                } else if (response.data.code === 403) {
                                                    setNotificationMessage(t('tokenExpiredLoginAgain')); 
                                                    setShowNotification(true);
                                                    setTimeout(() => {
                                                        localStorage.removeItem('userData');
                                                        navigate('/login', { replace: true });
                                                    }, 3000);
                                                }
                                            })
                                            .catch((error) => {
                                                console.error("Error occurred:", error);
                                                setNotificationMessage(t('errorOccurred')); 
                                                setShowNotification(true);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                            });
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form className='mt-4'>
                                            <label>{t('name')}:</label> 
                                            <Field className="field mt-1" type="text" name="name" value={userData.name || ''} disabled />
                                            <br />
                                            <label>{t('walletAddressTon')}:</label> 
                                            <Field type="text" className="field mt-1" name="wallet" placeholder={t('enterWalletAddress')} /> 
                                            <ErrorMessage name="wallet" component="div" style={{ marginRight: 8, marginTop: 5, color: 'red', fontSize: '0.8em' }} />
                                            <label>{t('tokenCount')}:</label> 
                                            <Field type="number" className="field mt-1" inputMode="numeric" name="tokens" placeholder={t('eachPack50k')} /> 
                                            <ErrorMessage name="tokens" component="div" className="my-2" style={{ marginRight: 8, color: 'red', fontSize: '0.8em' }} />
                                            <p className="has-text-light">{t('pricePerPack')}: {typeof tokenBuy === 'object' ? tokenBuy.price : tokenBuy} <strong className="ml-1 has-text-light">TRX</strong></p>
                                            <button type="submit" disabled={isSubmitting} className="btn mt-3">{t('buyToken')}</button> 
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className="column mobile_none is-7">
                                <img src="../assets/images/lottarypic.jpg" alt="LotteryImage" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </div>
    );
}