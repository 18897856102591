import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const HeadTitle = () => {
    const { t } = useTranslation();

    return (
        <Helmet>
            <title>{t('title')}</title>
            <meta name="description" content={t('welcomesite')} />
            <meta property="og:title" content={t('title')} />
            <meta property="og:description" content={t('welcomesite')} />
            <meta property="og:image" content="%PUBLIC_URL%/assets/images/logo.png" />
        </Helmet>
    );
};

export default HeadTitle;