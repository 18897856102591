import Header from "../components/Header";
import { Link, useNavigate, Navigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import Notification from "./Notification";
import MobileHeader from "./MobileHeader";
import Botmenu from "./Botmenu";
import { useTranslation } from 'react-i18next'; 

export default function Deposit() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [token, setToken] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userData, setUserData] = useState({});

    const handleInputChange = (e) => {
        const newValue = e.target.value.replace(/[^0-9]/g, '');
        setInputValue(newValue);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (parseInt(inputValue) < 103) {
            setNotificationMessage(t('minimumDeposit')); 
            setShowNotification(true);
            setTimeout(() => {
                setShowNotification(false);
            }, 3000);
            return; 
        }

        setIsSubmitting(true);
        const formData = new FormData();
        formData.append('amount', inputValue);

        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = userData.token;
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        };

        try {
            const response = await axios.post('https://luckyx.cloud/api/v2/user/deposit', formData, config);

            if (response && response.data && response.data.response && response.data.response.data) {
                const invoiceUrl = response.data.response.data.invoice_url;
                window.location.href = invoiceUrl;
            } else {
                setNotificationMessage(t('pleaseFillField'));
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                }, 3000);
            }
        } catch (error) {
            setNotificationMessage(t('depositError'));
            setShowNotification(true);
            setTimeout(() => {
                setShowNotification(false);
            }, 3000);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage(t('tokenExpired'));
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

     const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'fa' || i18n.language === 'ar';

    return (
        <div className={isRtl? 'rtl' : 'ltr'}>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section>
                    <div className="dep_m container">
                        <div className="columns h">
                            <div className="column has-text-centered-mobile">
                                <span className="has-text-light">TRX</span>
                            </div>
                            <div className="column">
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="0.0000trx"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    pattern="[0-9]*"
                                />
                            </div>
                            <div className="column">
                                <p>{t('minimumDepositAmount', { amount: 100 })}</p> 
                            </div>
                            <div className="column">
                                <button
                                    className="btn2"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <span>
                                            <i className="fas fa-spinner fa-spin" /> {t('loading')} // "درحال بارگذاری ..."
                                        </span>
                                    ) : (
                                        t('deposit') 
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </div>
    );
}