import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment-jalaali';
import Notification from "../components/Notification";
import MobileHeader from "../components/MobileHeader";
import Botmenu from "../components/Botmenu";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useTranslation } from "react-i18next";

export default function JoinLottary() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const [token, setToken] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'fa' || i18n.language === 'ar';

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                });
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage(t('tokenExpired'));
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    return (
        <div className={isRtl ? 'rtl' : 'ltr'}>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section>
                    <div className="container lotery_join_page mt-3">
                        <div className="columns join_lot">
                            <div className="column is-5">
                                <h2 className="title has-text-centered">{t('participateInLottery')}</h2>
                                <p>{t('buyTicketDescription')}</p>
                                <Formik
                                    initialValues={{ email: userData ? userData.email : '', tickets: '' }}
                                    validate={(values) => {
                                        const errors = {};
                                        if (!values.tickets) {
                                            errors.tickets = t('required');
                                        } else if (isNaN(parseInt(values.tickets, 10)) || parseInt(values.tickets, 10) < 1 || parseInt(values.tickets, 10) > 2) {
                                            errors.tickets = t('maxTwoTickets');
                                        }
                                        if (!values.email) {
                                            errors.email = t('required');
                                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                            errors.email = t('invalidEmail');
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(true);

                                        if (values.email !== userData.email) {
                                            setNotificationMessage(t('emailMismatch'));
                                            setShowNotification(true);
                                            setTimeout(() => {
                                                setShowNotification(false);
                                            }, 3000);
                                            setSubmitting(false);
                                            return;
                                        }

                                        if (userData.name === "unknown" || userData.name === null) {
                                            setNotificationMessage(t('editProfileName'));
                                            setShowNotification(true);
                                            setTimeout(() => {
                                                setShowNotification(false);
                                            }, 3000);
                                            setSubmitting(false);
                                            return;
                                        }

                                        const formData = new FormData();
                                        formData.append('buy', parseInt(values.tickets, 10));

                                        const token = userData.token;
                                        axios.post('https://luckyx.cloud/api/v2/user/buy', formData, {
                                            headers: {
                                                Authorization: `Bearer ${token}`,
                                                'Content-Type': 'multipart/form-data',
                                            },
                                        })
                                            .then((response) => {
                                                const er = response.data.error;

                                                if (response.data.code === 499) {
                                                    setNotificationMessage(er);
                                                    setShowNotification(true);
                                                    setTimeout(() => {
                                                        setShowNotification(false);
                                                    }, 3000);
                                                } else if (response.data.code === 404) {
                                                    setNotificationMessage(t('cannotBuyMoreThanTwo'));
                                                    setShowNotification(true);
                                                    setTimeout(() => {
                                                        setShowNotification(false);
                                                    }, 3000);
                                                } else if (response.data.code === 1) {
                                                    setNotificationMessage(t('purchaseSuccessful'));
                                                    setShowSuccessModal(true); 
                                                    setTimeout(() => {
                                                        setShowSuccessModal(false); 
                                                        navigate('/latary', { replace: true });
                                                    }, 10000);
                                                } else if (response.data.code === 403) {
                                                    setNotificationMessage(t('tokenExpiredLoginAgain'));
                                                    setShowNotification(true);
                                                    setTimeout(() => {
                                                        localStorage.removeItem('userData');
                                                        navigate('/login', { replace: true });
                                                    }, 3000);
                                                }
                                            })
                                            .catch((error) => {
                                                console.error(error.response);
                                                setNotificationMessage(t('errorOccurred'));
                                                setShowNotification(true);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                            });
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form className='mt-4'>
                                            <label>{t('name')}:</label>
                                            <Field className="field mt-1" type="text" name="name" value={userData ? userData.name : ''} disabled />
                                            <br />
                                            <label>{t('email')}:</label>
                                            <Field type="email" className="field mt-1" name="email" placeholder={t('enterEmail')} />
                                            <ErrorMessage name="email" component="div" style={{ marginRight: 8, marginTop: 5, color: 'red', fontSize: '0.8em' }} />
                                            <label>{t('ticketCountF')}:</label>
                                            <Field type="number" className="field mt-1" inputMode="numeric" name="tickets" placeholder={t('maxTwoTickets')} />
                                            <ErrorMessage name="tickets" component="div" style={{ marginRight: 8, marginTop: 5, color: 'red', fontSize: '0.8em' }} />
                                            <button type="submit" disabled={isSubmitting} className="btn mt-3">{t('buyTicket')}</button>
                                        </Form>
                                    )}
                                </Formik>
                                {showSuccessModal && (
                                    <div className="modal is-active">
                                        <div className="modal-background" onClick={() => setShowSuccessModal(false)}></div>
                                        <div className="modal-content">
                                            <div className="box">
                                                <p>{t('hopeBeWinner')}</p>
                                                <button className="button is-primary" onClick={() => setShowSuccessModal(false)}>{t('close')}</button>
                                            </div>
                                        </div>
                                        <button className="modal-close is-large" aria-label="close" onClick={() => setShowSuccessModal(false)}></button>
                                    </div>
                                )}
                                <Notification message={notificationMessage} open={showNotification} />
                            </div>
                            <div className="column mobile_none is-7">
                                <img src="../assets/images/lottarypic.jpg" alt="Lottery Image" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </div>
    );
}