import Header from "../components/Header";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment-jalaali';
import Notification from "../components/Notification";
import Botmenu from "../components/Botmenu";
import MobileHeader from "../components/MobileHeader";
import Config from "../Config";
import { useTranslation } from 'react-i18next'; 

export default function Rewards() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    let [token, setToken] = useState(null); 
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    let [data, setData] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const [joinedUsersCount, setJoinedUsersCount] = useState(1);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);

            try {
                const response = await axios.get(`${Config.apiurl}/user/boxref`, {
                    headers: { 'Authorization': `Bearer ${userDataJson.token}` },
                });

                if (Array.isArray(response.data.box)) {
                    setData(response.data);
                } else {
                    setData([]); 
                }
            } catch (error) {
                setData([]); 
            }
        } else {
            handleLogout();
        }
    };

    const formatDate = (ymd) => {
        ymd = ymd.toString();
        if (ymd.length !== 8) return ymd;

        const year = ymd.substring(0, 4);
        const month = ymd.substring(4, 6);
        const day = ymd.substring(6, 8);

        return `${year}/${month}/${day}`;
    };

    const displayedData = Array.isArray(data.box) ? data.box.slice(0, 7) : [];

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('userData');
        setIsLoggedIn(false);
        window.location.href = '/';
    };

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage(t('tokenExpired')); // "توکن منقضی شده است، دوباره وارد شوید"
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000); 
    };

    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'fa' || i18n.language === 'ar';

    return (
        <div className={isRtl? 'rtl' : 'ltr'}>
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <Notification message={notificationMessage} open={showNotification} />

                <section>
                    <div className="reward-main">
                        <div className="reward-inner">
                            <div className="columns">
                                <div className="columns is-flex mt-2">
                                    <div className="column is-flex is-align-items-center">
                                        <p>{t('myRewards')}:</p> 
                                    </div>
                                    <div className="column has-text-centered">
                                        <p>{t('totalRewards')}</p> 
                                        <div className="reward_info_top">
                                            <p>{data.total ? (<>{data.total}</>): (<>0</>)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="reward_info_bot">
                            <div className="columns">
                                <div className="column">
                                    <h2 className="has-text-centered">{t('dailyPerformance')}</h2> 
                                </div>
                                <div className="column table__sec">
                                    <table className="table is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th className="is-flex">{t('date')}</th>
                                                <th className="has-text-centered">{t('reward')}</th> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data ?
                                                <>
                                                    {data.box.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td>{formatDate(item.ymd)}</td>
                                                            <td className="has-text-centered">{item.total}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                                :
                                                <tr>
                                                    <td>{t('noData')}</td> 
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <nav className="mobile-navbar">
                        <Botmenu />
                    </nav>
                </section>
            </main>
        </div>
    );
}