import { CopyToClipboard } from 'react-copy-to-clipboard';
import Header from '../components/Header';
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkIcon from '@mui/icons-material/Link';
import axios from 'axios';
import Notification from '../components/Notification';
import Botmenu from '../components/Botmenu';
import MobileHeader from '../components/MobileHeader';
import { useTranslation } from 'react-i18next';

const ReferralCode = () => {
    const navigate = useNavigate();
    const [totalReward, setTotalReward] = useState(0);
    const [token, setToken] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [userData, setUserData] = useState(null);
    const [inviteLink, setInviteLink] = useState('');
    const [inviteLink2, setInviteLink2] = useState('');
    const [inviteTelegram, setInviteTelegram] = useState('');
    const [inviteTelegram2, setInviteTelegram2] = useState('');
    const [parentsCount, setParentsCount] = useState(0);
    const [refferal, setRefferal] = useState({ parents1: [], parents2: [] });
    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'fa' || i18n.language === 'ar';

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/myteam', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    const refferalData = response.data;
                    setRefferal(refferalData);
                });
        }
    }, [token]);

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        setInviteTelegram(`https://telegram.me/share/url?url=https://luckyx.cloud/sign-up?referral=${userData.r_code}&text=به ما بپیوندید!`);
                        setInviteTelegram2(`https://telegram.me/share/url?url=https://luckyx.cloud/sign-up?referral=${userData.l_code}&text=به ما بپیوندید!`);
                        setInviteLink(`https://luckyx.cloud/sign-up?referral=${userData.r_code}`);
                        setInviteLink2(`https://luckyx.cloud/sign-up?referral=${userData.l_code}`);
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                        setTotalReward(response.data.user.reward);
                    } else {
                        handleTokenChanged();
                    }
                });
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage(t('tokenExpired')); // "توکن منقضی شده است، دوباره وارد شوید"
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    const handleShareTelegram = () => {
        window.open(inviteTelegram, '_blank');
    }
    const handleShareTelegram2 = () => {
        window.open(inviteTelegram2, '_blank');
    }

    const handleShare = () => {
        navigator.clipboard.writeText(inviteLink)
            .then(() => {
                setNotificationMessage(t('linkCopied')); // "لینک شما کپی شد!"
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                }, 3000);
            });
    };
    const handleShare2 = () => {
        navigator.clipboard.writeText(inviteLink2)
            .then(() => {
                setNotificationMessage(t('linkCopied')); // "لینک شما کپی شد!"
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                }, 3000);
            });
    };

    return (
        <div className={isRtl? 'rtl' : 'ltr'}>
            <Notification message={notificationMessage} open={showNotification} />

            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section>
                    <div className="referral-code mt-3">
                        <div className="columns res">
                            <div className="column head_info">
                                <h2 className='has-text-centered'>{t('referralLink')}</h2> 
                            </div>
                            <div className="column telegram_ref_code">
                                <p>{t('inviteTelegram')}</p>
                                <p className='mt-4'>{t('inviteTelegramDescription')}</p> 
                                <button onClick={handleShareTelegram}>
                                    <TelegramIcon className='iconam' /> {t('shareRightTelegram')} 
                                </button>
                                <button onClick={handleShareTelegram2}>
                                    <TelegramIcon className='iconam' /> {t('shareLeftTelegram')} 
                                </button>
                            </div>
                            <div className="column mt-3 site_ref_code">
                                <p>{t('inviteSiteCodeRight')}</p> 
                                <p className='mt-4 has-text-left'>{inviteLink}</p>
                                <button onClick={handleShare}>
                                    <LinkIcon className='iconam' /> {t('copyLink')} 
                                </button>
                            </div>
                            <div className="column mt-3 site_ref_code">
                                <p>{t('inviteSiteCodeLeft')}</p> 
                                <p className='mt-4 has-text-left'>{inviteLink2}</p>
                                <button onClick={handleShare2}>
                                    <LinkIcon className='iconam' /> {t('copyLink')}
                                </button>
                            </div>
                            <div className="column ref_info">
                                <div className="columns is-flex is-justify-content-space-between">
                                    <div className="column">
                                        <Link to='/profile/rewards'>
                                            <button>{t('directInviteReport')}</button> 
                                        </Link>
                                    </div>
                                    <div className="column">
                                        <Link to='/profile/box'>
                                            <button>{t('organizationalChartReport')}</button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="columns is-flex">
                                    <div className="column">
                                        <p>{t('totalProfit')}</p>
                                    </div>
                                    <div className="column has-text-left">
                                        <p>{totalReward > 0 ? (<>{totalReward}</>) : (<>0</>)} trx</p>
                                    </div>
                                </div>
                                <div className="columns is-flex">
                                    <div className="column tolink">
                                        <Link to={'/ref'}>{t('showDirectTeam')}</Link> 
                                    </div>
                                </div>
                                <div className="columns is-flex">
                                    <div className="column tolink">
                                        <Link to={'/chart'}>{t('showOrganizationalChart')}</Link> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </div>
    );
};

export default ReferralCode;