import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from "../components/Header";
import Notification from "../components/Notification";
import Botmenu from "../components/Botmenu";
import MobileHeader from "../components/MobileHeader";

const PlatformInfo = () => {
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'fa' || i18n.language === 'ar';


    return (
        <div className={isRtl ? 'rtl' : 'ltr'}>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>

            </header>
            <main>
                <section>
                    <div className="container about__us mt-5">
                        <section className="intro">
                            <h2>{t('platform_intro')}</h2>
                            <p>{t('greeting')}</p>
                        </section>

                        <section className="about-us">
                            <h2 className='has-text-centered'>{t('about_us')}</h2>
                            <p>{t('about_us_content')}</p>
                        </section>

                        <section className="team">
                            <div className="is-flex  ">
                                <img src="../assets/images/arnoldas.jpeg" alt="arnoldas" />
                                <span>
                                    <h2>{t('team')}</h2>
                                    <h3>{t('founder')}</h3>
                                </span>
                            </div>
                            <p>{t('founder_info')}</p>
                        </section>

                        <section className="product-roadmap">
                            <h2 className='has-text-centered'>{t('product_roadmap')}</h2>
                            <p className='has-text-centered'>{t('roadmap_content')}</p>
                        </section>

                        <section className="steps">
                            <div className="in1">
                                <div className="is-flex is-justify-content-space-between">
                                    <h2>{t('step1')}</h2>
                                    <h3>P1</h3>
                                </div>
                                <p>{t('step1_content')}</p>
                            </div>

                            <div className="in2">
                                <div className="is-flex is-justify-content-space-between">
                                    <h2>{t('step2')}</h2>
                                    <h3>P2</h3>
                                </div>
                                <p>{t('step2_content')}</p>
                            </div>

                            <div className="in3">
                                <div className="is-flex is-justify-content-space-between">
                                    <h2>{t('step3')}</h2>
                                    <h3>P3</h3>
                                </div>
                                <p>{t('step3_content')}</p>
                            </div>

                            <div className="in4">
                                <div className="is-flex is-justify-content-space-between">
                                    <h2>{t('step4')}</h2>
                                    <h3>P4</h3>
                                </div>
                                <p>{t('step4_content')}</p>
                            </div>

                            <div className="in5">
                                <div className="is-flex is-justify-content-space-between">
                                    <h2>{t('step5')}</h2>
                                    <h3>P5</h3>
                                </div>
                                <p>{t('step5_content')}</p>
                            </div>

                            <div className="in6">
                                <div className="is-flex is-justify-content-space-between">
                                    <h2>{t('final_step')}</h2>
                                    <h3>P6</h3>
                                </div>
                                <p>{t('final_step_content')}</p>
                            </div>
                        </section>

                        <section className="pricing-plan">
                            <h2>{t('pricing_plan')}</h2>
                            <p>{t('pricing_content')}</p>
                        </section>

                        <section className="products-services">
                            <h2 className='has-text-centered'>{t('products_services')}</h2>

                            <div className="in1">
                                <h3>{t('product1')}</h3>
                                <p>{t('product1_content')}</p>
                            </div>

                            <div className="in2">
                                <h3>{t('product2')}</h3>
                                <p>{t('product2_content')}</p>
                            </div>
                        </section>

                        <section className="income-plan">
                            <h2>{t('income_plan')}</h2>

                            <div className="in1">
                                <h3>{t('income_plan1')}</h3>
                                <p>{t('income_plan1_content')}</p>
                            </div>

                            <div className="in2">
                                <h3>{t('income_plan2')}</h3>
                                <p>{t('income_plan2_content')}</p>
                            </div>
                        </section>

                        <section className="welcome">
                            <h2>{t('welcome')}</h2>
                            <p>{t('welcome_content')}</p>
                        </section>
                    </div>
                </section>
            </main>
            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>

        </div>
    );
};

export default PlatformInfo;