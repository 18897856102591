import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import BarChartIcon from '@mui/icons-material/BarChart';
import Notification from "../components/Notification";
import Botmenu from "../components/Botmenu";
import MobileHeader from "../components/MobileHeader";
import { QRCodeCanvas } from 'qrcode.react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupsIcon from '@mui/icons-material/Groups';
import StarsIcon from '@mui/icons-material/Stars';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import VerifiedIcon from '@mui/icons-material/Verified';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ParkIcon from '@mui/icons-material/Park';
import { useTranslation } from 'react-i18next';
import Config from "../Config";

export default function Profile() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [token, setToken] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const UserToken = userData.token
    const [inviteLink, setInviteLink] = useState('');
    const [inviteLink2, setInviteLink2] = useState('');


    const [childs, setchild] = useState('...');

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);


            axios.get(`${Config.apiurl}/user/box`, {
                headers: {
                    Authorization: `Bearer ${userDataJson.token}`,
                },
            }).then(res => {
                setchild(res.data.countchilds);
            });


        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get(`${Config.apiurl}/user/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        setInviteLink(`https://luckyx.cloud/sign-up?referral=${userData.r_code}`);
                        setInviteLink2(`https://luckyx.cloud/sign-up?referral=${userData.l_code}`);
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                        setTotalReward(response.data.user.reward)
                    } else {
                        handleTokenChanged();
                    }
                });
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage(t('tokenExpired'));
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    const [totalReward, setTotalReward] = useState(0);

    const [refferal, setRefferal] = useState({ parents1: [], parents2: [] });

    useEffect(() => {
        if (token) {
            axios.get(`${Config.apiurl}/user/myteam`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    const refferalData = response.data;
                    setRefferal(refferalData);

                })

        }
    }, [token]);


    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'fa' || i18n.language === 'ar';


    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        axios.get('https://luckyx.cloud/api/v2/user/mytickets', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setTickets(response.data.tickets);
            })
    }, [token]);

    if (!userData) {
        return null;
    }

    const handleShare = () => {
        navigator.clipboard.writeText(inviteLink)
            .then(() => {
                setNotificationMessage(t('linkCopied'));
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                }, 3000);
            });
    };

    const handleShare2 = () => {
        navigator.clipboard.writeText(inviteLink2)
            .then(() => {
                setNotificationMessage(t('linkCopied'));
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                }, 3000);
            });
    };



    return (
        <div className={isRtl ? 'rtl' : 'ltr'}>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section>
                    <div className="profile_bod">
                        <div className="link__section mt-4">
                        <div className="columns is-flex is-multiline">
                                <div className="column is-12-mobile is-flex is-justify-content-space-between ">
                                    <button onClick={handleShare}>
                                        {t('sharertl')}
                                    </button>
                                    <button onClick={handleShare2}>
                                        {t('shareltr')}
                                    </button>

                                </div>
                                <div className="column is-12-mobile is-flex is-justify-content-space-around">
                                    <QRCodeCanvas value={inviteLink} size={128}/>
                                    <QRCodeCanvas value={inviteLink2} size={128}/>
                                </div>
                            </div>
                        </div>

                        <div className="winners mt-4">
                            <div className="columns is-flex">
                                <div className="column is-align-items-center is-flex ">
                                    <img src="../assets/icon/giftt.png" alt="" />
                                    <p className="mx-3">{t('winners')}</p>
                                </div>
                                <div className="column is-justify-content-flex-end is-align-items-center is-flex ">
                                    <Link to={'/latary/winners'}>
                                        <button>
                                            {t('viewAll')}
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="status mt-4">
                            <div className="columns">
                                <div className="columns is-flex is-align-items-center">
                                    <div className="column  is-align-items-center is-flex">
                                        <BarChartIcon className="iconm mx-2" />
                                        <p className=" mt-1">{t('myStats')}</p>
                                    </div>
                                    
                                </div>
                                <div className="column is-flex is-justify-content-space-between">
                                    <div className="info is-flex is-justify-content-space-between is-flex-direction-column">
                                        <p className="has-text-centered">{t('level1Members')}</p> 
                                        <div className="has-text-centered">
                                            {refferal.parents1?.length > 0 ? refferal.parents1.length : 0}
                                        </div>
                                    </div>
                                    <div className="info is-flex is-justify-content-space-between is-flex-direction-column mx-2">
                                        <p className="has-text-centered">{t('organizationalChartMembers')}</p>
                                        <div className="has-text-centered">
                                            {childs}
                                        </div>
                                    </div>
                                    <div className="info is-flex is-justify-content-space-between is-flex-direction-column">
                                        <p className="has-text-centered">{t('receivedReward')}</p>
                                        <div className="has-text-centered">{userData.reward > 0 ? (<>{totalReward}</>) : (<>0</>)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="info_menu mt-5">
                            <div className="info_menu_inner">
                                <Link to={'/Wallet'}>
                                    <div className="info_menu_inner_menus is-flex">
                                        <AccountBalanceWalletIcon className="iconmn" />
                                        <h2>{t('yourWallet')}</h2>
                                    </div>
                                </Link>
                                <Link to={'/profile/tickets'}>
                                    <div className="info_menu_inner_menus is-flex">
                                        <LocalActivityIcon className="iconmn" />
                                        <h2>{t('myTickets')}</h2>
                                    </div>
                                </Link>
                                <Link to={'/token'}>
                                    <div className="info_menu_inner_menus is-flex">
                                        <ConfirmationNumberIcon className="iconmn" />
                                        <h2>{t('myTokens')}</h2>
                                    </div>
                                </Link>
                                <Link to={'/latary/winners'}>
                                    <div className="info_menu_inner_menus is-flex">
                                        <EmojiEventsIcon className="iconmn" />
                                        <h2>{t('prizeWinners')}</h2>
                                    </div>
                                </Link>
                                <Link to={'/Verify'}>
                                    <div className="info_menu_inner_menus is-flex">
                                        <VerifiedIcon className="iconmn" />
                                        <h2>{t('verifyWithdrawalAddress')}</h2>
                                    </div>
                                </Link>
                                <Link to={'/referral-code'}>
                                    <div className="info_menu_inner_menus is-flex">
                                        <SupervisorAccountIcon className="iconmn" />
                                        <h2>{t('inviteFriends')}</h2>
                                    </div>
                                </Link>
                                <Link to={'/profile/rewards'}>
                                    <div className="info_menu_inner_menus is-flex">
                                        <StarsIcon className="iconmn" />
                                        <h2>{t('directInvitationReward')}</h2>
                                    </div>
                                </Link>
                                <Link to={'/profile/box'}>
                                    <div className="info_menu_inner_menus is-flex">
                                        <MilitaryTechIcon className="iconmn" />
                                        <h2>{t('organizationalChartReward')}</h2>
                                    </div>
                                </Link>
                                <Link to={'/ref'}>
                                    <div className="info_menu_inner_menus is-flex">
                                        <GroupsIcon className="iconmn" />
                                        <h2>{t('yourFriends')}</h2>
                                    </div>
                                </Link>
                                <Link to={'/chart'}>
                                    <div className="info_menu_inner_menus is-flex">
                                        <ParkIcon className="iconmn" />
                                        <h2>{t('displayOrganizationalChart')}</h2>
                                    </div>
                                </Link>
                                <Link to={'/setting'}>
                                    <div className="info_menu_inner_menus is-flex">
                                        <ChangeCircleIcon className="iconmn" />
                                        <h2>{t('editProfile')}</h2>
                                    </div>
                                </Link>
                                <hr style={{ width: '100em', position: 'relative', left: '2em', marginBottom: '7em' }} />
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </div>
    );
}