import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment-jalaali';
import Notification from "../components/Notification";
import MobileHeader from "../components/MobileHeader";
import Botmenu from "../components/Botmenu";
import { useTranslation } from 'react-i18next';
import momentHijri from 'moment-hijri';

export default function Winners() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const [token, setToken] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [lotteryData, setLotteryData] = useState(null);
    const [winners, setWinnersData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'fa' || i18n.language === 'ar';

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                });
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage(t('tokenExpired'));
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };


    useEffect(() => {
        const fetchWinnerData = async () => {
            try {
                const response = await axios.get('https://luckyx.cloud/api/v2/winners');
                if (response.data.code === 1) {
                    setWinnersData(response.data.wiiners || []);
                    console.log(response.data)
                } else {
                    setWinnersData([]);
                }
            } catch (error) {
                // console.error(error);
            }
        };

        fetchWinnerData();
    }, []);


    useEffect(() => {
        const fetchLotteryData = async () => {
            try {
                const response = await axios.get('https://luckyx.cloud/api/v2/lastlottery');
                setLotteryData(response.data);
            } catch (error) {
                // console.error(error);
            }
        };

        fetchLotteryData();
    }, []);

    if (!lotteryData) {
        return <div>{t('loading')}...</div>;
    }

    const { lottery, tickets } = lotteryData;

    const formatDate = (timestamp) => {
        if (!timestamp) return t('unknown');
    
        const date = moment.unix(timestamp);
    
        if (i18n.language === 'fa') {
            return date.format('jYYYY/jMM/jDD');
        } else if (i18n.language === 'ar') {
            const gregorianDate = date.format('YYYY-MM-DD'); 
            return momentHijri(gregorianDate).format('iYYYY/iMM/iDD'); 
        } else {
            return date.format('YYYY/MM/DD');
        }
    };

    

    const startTime = formatDate(lottery.start_time);
    const endTime = formatDate(lottery.end_time);
    const winner = lottery.winner !== null ? lottery.winner : t('unknown');
    const ticketCount = tickets.length;


    return (
        <div className={isRtl ? 'rtl' : 'ltr'}>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section>
                    <div className="container winner__page">
                        <h1 className="title">{t('previousLotteryInfo')}</h1>
                        <div className="columns">
                            <div className="column">
                                <div className="box">
                                    <h2 className="subtitle">{t('startDate')}:</h2>
                                    <p>{startTime}</p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="box">
                                    <h2 className="subtitle">{t('endDate')}:</h2>
                                    <p>{endTime}</p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="box">
                                    <h2 className="subtitle">{t('winner')}:</h2>
                                    <p>{winner}</p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="box">
                                    <h2 className="subtitle">{t('ticketCount')}:</h2>
                                    <p>{ticketCount}</p>
                                </div>
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column has-text-centered">
                                <button className="button is-info" onClick={() => setShowModal(true)}>
                                    {t('viewAllWinners')}
                                </button>
                            </div>
                        </div>

                        {showModal && (
                            <div className="modal is-active">
                                <div className="modal-background" onClick={() => setShowModal(false)}></div>
                                <div className="modal-content">
                                    <div className="box">
                                        <h2 className="title has-text-centered">{t('allWinners')}</h2>
                                        <div className="has-text-centered">
                                            <img src="../assets/images/winner.png" width={200} alt="winners" />
                                        </div>

                                        <table className="table is-fullwidth">
                                            <thead>
                                                <tr>
                                                    <th className="has-text-centered">{t('name')}</th>
                                                    <th className="has-text-centered">{t('email')}</th>
                                                    <th className="has-text-centered">{t('date')}</th>
                                                    <th className="has-text-centered">{t('referrer')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {winners.map((winner, index) => (
                                                    <tr key={index}>
                                                        <td className="table-cell scrollable">{winner.name}</td>
                                                        <td className="table-cell scrollable">{winner.email}</td>
                                                        <td className="table-cell">{formatDate(winner.date)}</td>
                                                        <td className="table-cell scrollable">
                                                            {winner.has_parent ? (
                                                                `${winner.name_parent} (${winner.email_parent})`
                                                            ) : (
                                                                t('noReferrer')
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <button className="button" onClick={() => setShowModal(false)}>{t('close')}</button> {/* "بستن" */}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </div>
    );
}