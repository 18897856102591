import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const FullScreenSlider = () => {
    const { t } = useTranslation(); // Use the translation hook
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 30000,
    };

    return (
        <div className="full-screen-slider">
            <Slider {...settings}>
                <div className="slide">
                    <img src="../assets/images/heropic.jpg" alt="Slide 1" />
                    <div className="text-overlay">
                        <p>{t('specialCommission')}</p> 
                        <h2>{t('referFriends')}</h2> 
                    </div>
                </div>
                <div className="slide">
                    <img src="../assets/images/heroimg3.jpg" alt="Slide 3" />
                    <div className="text-overlay">
                        <p>{t('specialCommission')}</p> 
                        <h2>{t('referFriends')}</h2> 
                    </div>
                </div>
            </Slider>
            <div className="button-overlay ">
                <div className='columns inner'>
                    {isLoggedIn ? (
                        <></>
                    ) : (
                        <>
                            <div className='column is-flex'>
                                <div className="buttonn is-flex">
                                    <Link to={'/sign-up'} className="btnn fx01">
                                        <span>{t('register')}</span>
                                    </Link>
                                </div>
                                <div className="buttonn ">
                                    <Link to={'/login'} className="btnn fx02">
                                        <span>{t('login')}</span> 
                                    </Link>
                                </div>
                            </div>
                            <div className="text has-text-centered has-text-light">
                                <span className="line"></span>
                                {t('or')}
                                <span className="line"></span>
                            </div>

                            <div className="google_telegram has-text-centered">
                                <button>
                                    <img src="../assets/icon/google.png" alt="" />
                                    <p>{t('google')}</p> 
                                </button>
                                <Link to={"https://t.me/TrainingLuckyCchanel"}>
                                    <button>
                                        <img src="../assets/icon/telegram.png" alt="" />
                                        <p>{t('telegram')}</p> 
                                    </button>
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FullScreenSlider;