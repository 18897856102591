import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'; 
import LanguageSwitcher from "../components/LanguageSwitcher";

export default function Botmenu() {
    const { t } = useTranslation(); // Use the translation hook
    const [selected, setSelected] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    const handleClick = (item) => {
        setSelected(item);
    };

    return (
        <>
            
            <ul>
                <li className="has-text-centered">
                    <Link to="/menu" className={selected === 'menu' ? 'selected' : ''} onClick={() => handleClick('menu')}>
                        <img src="../assets/icon/menu.png" width={40} className="mobileMenu_icons" />
                        <p>{t('menu')}</p>
                    </Link>
                </li>
                {isLoggedIn ? (
                    <li className="has-text-centered">
                        <Link to="/Profile" className={selected === 'Profile' ? 'selected' : ''} onClick={() => handleClick('Profile')}>
                            <img src="../assets/icon/profile.png" width={35} className="mobileMenu_icons" />
                            <p>{t('profile')}</p>
                        </Link>
                    </li>
                ) : (
                    <li className="has-text-centered">
                        <Link to={"/login"} className={selected === 'home' ? 'selected' : ''} onClick={() => handleClick('home')}>
                            <img src="../assets/icon/login.png" width={33} className="mobileMenu_icons" />
                            <p>{t('login')}</p>
                        </Link>
                    </li>
                )}

                <li className="has-text-centered">
                    <Link to="/wallet" className={selected === 'wallet' ? 'selected' : ''} onClick={() => handleClick('wallet')}>
                        <img src="../assets/icon/wallett.png" width={38} className="mobileMenu_icons" />
                        <p>{t('yourWallet')}</p>
                    </Link>
                </li>

                <li className="has-text-centered">
                    <Link to="/latary" className={selected === 'lottery' ? 'selected' : ''} onClick={() => handleClick('lottery')}>
                        <img src="../assets/icon/ticket.svg" width={38} className="mobileMenu_icons" />
                        <p>{t('lottery')}</p>
                    </Link>
                </li>
            </ul>
        </>
    );
}