import Header from "../components/Header";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import MobileHeader from "../components/MobileHeader";
import Botmenu from "../components/Botmenu";
import Notification from "../components/Notification";
import moment from 'moment-jalaali'; 
import { useTranslation } from 'react-i18next';
import momentHijri from 'moment-hijri'; 

export default function TokenInfo() {
    const navigate = useNavigate();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [token, setToken] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const [tickets, setTickets] = useState('');
    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'fa' || i18n.language === 'ar';

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/mytokens', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
                .then(response => {
                    setTickets(response.data.mytokens);
                })
                .catch(error => {
                    // console.error(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                });
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage(t('tokenExpired')); 
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return t('unknown');
    
        const date = moment.unix(timestamp);
    
        if (i18n.language === 'fa') {
            return date.format('jYYYY/jMM/jDD');
        } else if (i18n.language === 'ar') {
            const gregorianDate = date.format('YYYY-MM-DD'); 
            return momentHijri(gregorianDate).format('iYYYY/iMM/iDD'); 
        } else {
            return date.format('YYYY/MM/DD');
        }
    };


    return (
        <div className={isRtl? 'rtl' : 'ltr'}>
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <Notification message={notificationMessage} open={showNotification} />

                <section className="px-2">
                    <div className="container ticket_page">
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead style={{ borderBottom: '1px solid white' }}>
                                <tr>
                                    <th className="p-3">{t('count')}</th> 
                                    <th className="p-3">{t('wallet')}</th> 
                                    <th className="p-3">{t('date')}</th> 
                                </tr>
                            </thead>
                            <tbody>
                                {tickets?.length > 0 ? (
                                    tickets.map(currentTicket => (
                                        <tr key={currentTicket.id}>
                                            <td className="p-3">{currentTicket.count}</td>
                                            <td className="p-3 scrollable_cell">{currentTicket.wallet}</td>
                                            <td className="p-3">
                                                {formatDate(currentTicket.date)}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr style={{ borderTop: '1px solid #000' }}>
                                        <td className="p-3" colSpan={3}>{t('noTickets')}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </section>

                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </main>
        </div>
    );
}