import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation(); // Get i18n instance
    const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
    const dropdownRef = useRef(null); // Reference for dropdown

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng); // Change language
        setIsOpen(false); // Close dropdown after selection
    };

    // Function to get the flag image based on the current language
    const getFlagImage = (language) => {
        switch (language) {
            case 'fa':
                return './assets/icon/fa.png';
            case 'en':
                return './assets/icon/en.png';
            case 'ar':
                return './assets/icon/ar.png';
            case 'tr':
                return './assets/icon/tr.png';
            default:
                return '';
        }
    };

    return (
        <div className="language-switcher">
            <button onClick={() => setIsOpen(!isOpen)}>
                <img src={getFlagImage(i18n.language)} alt={i18n.language} className="flag" />
                {i18n.language === 'fa' ? 'فارسی' :
                    i18n.language === 'en' ? 'English' :
                        i18n.language === 'ar' ? 'العربية' :
                            'Türkçe'}
                <KeyboardArrowDownIcon className={`arrow ${isOpen ? 'open' : ''}`} />
            </button>
            {isOpen && (
                <div className="dropdown" ref={dropdownRef}>
                    <button onClick={() => changeLanguage('fa')}>
                        <img src={"./assets/icon/fa.png"} alt="فارسی" className="flag" /> فارسی
                    </button>
                    <button onClick={() => changeLanguage('en')}>
                        <img src={'./assets/icon/en.png'} alt="English" className="flag" /> English
                    </button>
                    <button onClick={() => changeLanguage('ar')}>
                        <img src={'./assets/icon/ar.png'} alt="العربية" className="flag" /> العربية
                    </button>
                    <button onClick={() => changeLanguage('tr')}>
                        <img src={'./assets/icon/tr.png'} alt="Türkçe" className="flag" /> Türkçe
                    </button>
                </div>
            )}
        </div>
    );
};

export default LanguageSwitcher;