import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Notification from "../components/Notification";
import EmailIcon from '@mui/icons-material/Email';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Botmenu from "../components/Botmenu";
import Config from "../Config";
import { useTranslation } from 'react-i18next'; 

const Login = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [timer, setTimer] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const toggleDropdown = () => {
        setIsOpenMenu(!isOpenMenu);
    };

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setIsButtonDisabled(false);
        setTimer(null);
    };

    const handleSendEmail = () => {
        setIsButtonDisabled(true);
        setTimer(60);
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(intervalId);
            setIsButtonDisabled(false);
            setTimer(null);
        }, 60000);
    };

    const [email2, setEmail2] = useState('');
    const [password2, setPassword2] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const loginn = async (event) => {
        const formData = new FormData();
        formData.append('email', email2);
        formData.append('password', password2);
        event.preventDefault();
        axios.post(`${Config.apiurl}/login`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                if (response.data.code === 1) {
                    const userData = response.data.user;
                    localStorage.setItem('userData', JSON.stringify(userData));
                    navigate('/profile', { replace: true });
                    setNotificationMessage(t('profileUpdated')); 
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 3000); 
                } else {
                    setNotificationMessage(t('invalidEmailOrPassword')); 
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 3000);
                }
            });
    };

        const { t, i18n } = useTranslation();
        const isRtl = i18n.language === 'fa' || i18n.language === 'ar';

    return (
        <div className={isRtl? 'rtl Login' : 'ltr Login' }>
            <Notification message={notificationMessage} open={showNotification} />
            <section>
                <div className="menu__top__login columns is-flex is-justify-content-space-between mt-1 px-1">
                    <button onClick={toggleDropdown} className="dropdown-button">
                        {isOpenMenu ? t('close') : t('educationalMenu')}
                    </button>

                    {isOpenMenu && (
                        <div className="show_menu">
                            <div className="column is-12-mobile ">
                                <Link to="/educational-videos">{t('educationalVideos')}</Link> 
                            </div>
                            <div className="column is-12-mobile ">
                                <Link to="/rewards">{t('rewards')}</Link>
                            </div>
                            <div className="column is-12-mobile ">
                                <Link to="/support">{t('support')}</Link> 
                            </div>
                        </div>
                    )}
                </div>
            </section >

            <section className='login__bod'>
                <div className="google_telegram has-text-centered is-flex">
                    <Link to={'#'} style={{ marginLeft: '5px', marginRight: '5px' }}>
                        <button >
                            <img src="../assets/icon/google.png" width={20} alt="" className="ml-2" />
                            <p>{t('google')}</p>
                        </button>
                    </Link>
                    <Link to={"https://t.me/TrainingLuckyCchanel"}>
                        <button>
                            <img src="../assets/icon/telegram.png" width={20} className="ml-2" alt="" />
                            <p>{t('telegram')}</p>
                        </button>
                    </Link>
                </div>
                <div className="text has-text-centered mt-4">
                    <span className="line"></span>
                    {t('orLoginWith')}
                    <span className="line"></span>
                </div>
                <div className="login-form mt-6 px-2">
                    <div className="input-container columns">
                        <div className="column lab is-2 is-2-mobile">
                            <EmailIcon className="iconm" />
                        </div>
                        <div className="column">
                            <label className="input-label">{t('emailAddress')}</label> {/* Use translation */}
                            <input
                                className="input-field"
                                type="text"
                                value={email2}
                                onChange={(e) => setEmail2(e.target.value)}
                                placeholder={t('enterYourEmail')}
                            />
                        </div>
                    </div>

                    <div className="input-container columns">
                        <div className="column is-2 is-2-mobile lab">
                            <VpnKeyIcon className="iconm" />
                        </div>
                        <div className="column">
                            <label className="input-label">{t('password')}</label> 
                            <input
                                className="input-field"
                                type={showPassword ? 'text' : 'password'}
                                value={password2}
                                onChange={(e) => setPassword2(e.target.value)}
                                placeholder={t('enterYourPassword')} 
                            />
                        </div>
                        <div className="column is-2 is-2-mobile lab" onClick={togglePasswordVisibility}>
                            <RemoveRedEyeIcon className="iconm" style={{ cursor: 'pointer' }} />
                        </div>

                        <div className={`modal ${isOpen ? "is-active" : ""}`}>
                            <div className="modal-background" onClick={handleCloseModal} />
                            <div className="modal-card">
                                <header className="modal-card-head">
                                    <p className="modal-card-title is-size-6 has-text-light">{t('requestPasswordReset')}</p> {/* Use translation */}
                                    <button
                                        className="delete"
                                        aria-label="close"
                                        onClick={handleCloseModal}
                                        style={{ padding: 10 }}
                                    />
                                </header>
                                <section className="modal-card-body">
                                    <a href="https://t.me/ownerluckyX" style={{ color: '#000' }}>
                                        <button
                                            onClick={handleSendEmail}
                                            className="button is-primary"
                                            disabled={isButtonDisabled}
                                            style={{ display: 'block', position: 'relative', top: 0, left: 0, padding: 20 }}
                                        >
                                            {t('contactAdmin')} {/* Use translation */}
                                        </button>
                                    </a>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div className="password_forgot has-text-centered pb-4">
                        <span onClick={handleOpenModal}>
                            {t('forgotPassword')} {/* Use translation */}
                        </span>
                    </div>
                </div>

                <div className="is-flex is-justify-content-space-between">
                    <button
                        className="mb-4"
                        onClick={loginn}
                        style={{ backgroundColor: "#2C2F33" }}
                    >
                        {t('login')} {/* Use translation */}
                    </button>
                </div>

                <div className="mt-3 has-text-centered">
                    <p className="login__to__signUp">
                        {t('dontHaveAccount')} {/* Use translation */}
                        <Link to={"/sign-up"} className="mr-2">
                            {t('signUp')} {/* Use translation */}
                        </Link>
                    </p>
                </div>
            </section>

            <section>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </section>
        </div>
    );
};

export default Login;